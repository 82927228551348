import React from 'react';

const TeamLeafPattern = () => (
    <svg width="720" height="749" viewBox="0 0 720 749" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.9368 395.841C39.9368 395.841 104.115 289.49 205.316 288.811C306.517 288.133 370.617 412.416 443.262 416.84C443.262 416.84 279.845 572.229 161.095 510.207C62.8616 458.658 39.9368 395.841 39.9368 395.841Z" fill="#1B365D" fillOpacity="0.1"/>
        <path d="M329.788 42.6847C329.788 42.6847 453.757 46.9065 503.658 135.215C553.559 223.523 476.134 339.668 507.528 405.285C507.528 405.285 292.31 337.937 288.799 204.104C286.053 93.2099 329.788 42.6847 329.788 42.6847Z" fill="#1B365D" fillOpacity="0.1"/>
        <path d="M320.773 588.958C320.773 588.958 373.789 476.6 474.479 465.927C575.223 454.964 651.444 572.239 724.009 569.15C724.009 569.15 577.117 740.258 452.775 690.376C350.201 649.105 320.773 588.958 320.773 588.958Z" fill="#1B365D" fillOpacity="0.1"/>
        <path d="M573.567 208.107C573.567 208.107 697.517 199.728 755.971 282.453C814.424 365.179 749.101 488.709 787.161 550.786C787.161 550.786 566.229 505.753 549.319 372.988C535.022 262.912 573.567 208.107 573.567 208.107Z" fill="#1B365D" fillOpacity="0.1"/>
    </svg>
)

export default TeamLeafPattern;