import React from 'react';

const FourFootsteps = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="211"
      height="343"
      fill="none"
      viewBox="0 0 211 343"
    >
      <path
        fill="#7C5939"
        d="M94.579 6.814c6.78 3.91 10.623 14.442 11.697 19.22-7.014-.595-17.962 6.238-22.559 9.729-3.53-4.968-10.149-16.171-8.38-21.245C77.55 8.176 86.103 1.928 94.58 6.814zM86.858 46.254c9.096 3.083 20.749-2.408 25.439-5.54 14.067 21.281 11.194 36.775 5.448 45.552-3.265 4.988-9.836 7.27-15.333 4.777C87.3 84.193 85.518 59.56 86.858 46.254zM149.985 187.3c7.697-1.384 17.562 4.168 21.533 7.118-5.68 4.059-9.411 16.31-10.567 21.928-5.947-1.513-18.348-5.791-20.371-10.796-2.53-6.255-.216-16.521 9.405-18.25zM170.259 222.32c8.896-3.504 14.047-15.186 15.51-20.589 24.581 7.134 32.701 20.707 34.224 31.076.878 5.974-2.619 12.026-8.443 13.651-15.906 4.436-33.508-13.153-41.291-24.138zM19.447 79.038c-4.387 6.43-3.177 17.511-2.023 22.247 6.071-3.568 18.998-2.2 24.702-1.069.98-5.97 1.979-18.852-1.875-22.622C35.435 72.882 24.93 71 19.447 79.038zM43.953 110.955c-6.846 6.695-19.813 6.831-25.44 6.063-3.264 25.242 6.33 37.85 15.464 43.162 5.101 2.967 11.925 2.182 15.781-2.383 10.691-12.655 1.332-35.507-5.805-46.842zM113.068 268.134c-.687 7.657 5.864 16.717 9.226 20.289 3.589-5.965 15.668-10.871 21.259-12.579-2.089-5.651-7.583-17.317-12.848-18.776-6.581-1.824-16.779 1.494-17.637 11.066zM150.479 284.316c-2.725 9.047-14.095 15.281-19.44 17.267 9.654 23.575 24.439 29.963 35.135 30.224 5.89.143 11.435-3.696 12.594-9.45 3.236-16.064-16.367-31.546-28.289-38.041z"
      ></path>
    </svg>
  )

export default FourFootsteps;
