import React from 'react';

const InnovationIcon = () => (
  <svg
    width="284"
    height="284"
    viewBox="0 0 284 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M142 284C220.424 284 284 220.424 284 142C284 63.5756 220.424 0 142 0C63.5756 0 0 63.5756 0 142C0 220.424 63.5756 284 142 284Z"
      fill="#FFED8E"
    />
    <path
      d="M184.741 112.77C184.021 128.329 178.434 139.573 170.725 146.905C162.957 154.295 152.805 157.934 142.622 157.844C122.464 157.664 102.058 142.838 100.957 112.809C101.678 97.252 107.264 86.0091 114.973 78.6774C122.741 71.2891 132.893 67.6496 143.076 67.7404C163.235 67.9202 183.64 82.745 184.741 112.77Z"
      fill="#DAC036"
      stroke="#DAC036"
      strokeWidth="8"
    />
    <path
      d="M127.169 176.72C127.169 176.72 128.159 164.937 118.101 150.551C108.044 136.165 154.965 127.692 154.965 127.692L172.427 150.466C142.481 180.256 183.546 176.48 127.169 176.72Z"
      fill="#DEC025"
    />
    <path
      d="M139.978 246.274C139.978 247.86 141.263 249.145 142.849 249.145C144.435 249.145 145.721 247.86 145.721 246.274V191.043C145.721 189.457 144.435 188.171 142.849 188.171C141.263 188.171 139.978 189.457 139.978 191.043V246.274Z"
      fill="#DAC036"
    />
    <path
      d="M129.63 171.479C129.63 171.479 124.898 189.635 140.982 189.699C157.065 189.762 156.308 180.292 156.308 173.474"
      fill="#FBDD40"
    />
    <path
      d="M129.63 171.479C129.63 171.479 124.898 189.635 140.982 189.699C157.065 189.762 156.308 180.292 156.308 173.474"
      stroke="#DAC036"
      strokeWidth="7"
      strokeMiterlimit="10"
    />
    <path
      d="M158.359 176.72C158.359 176.72 157.369 164.937 167.42 150.551C177.47 136.165 130.556 127.692 130.556 127.692L113.094 150.466C143.04 180.256 101.975 176.48 158.359 176.72Z"
      fill="#DEC025"
    />
    <path
      d="M142.849 56.0377H142.856C144.149 56.0377 145.197 54.9896 145.197 53.6966V37.1959C145.197 35.9029 144.149 34.8548 142.856 34.8548H142.849C141.556 34.8548 140.508 35.9029 140.508 37.1959V53.6966C140.508 54.9896 141.556 56.0377 142.849 56.0377Z"
      fill="#DAC036"
    />
    <path
      d="M165.549 62.7532L165.556 62.7564C166.708 63.3424 168.118 62.8831 168.704 61.7305L176.182 47.0217C176.768 45.8692 176.309 44.4598 175.156 43.8738L175.15 43.8706C173.997 43.2847 172.588 43.7439 172.002 44.8965L164.524 59.6053C163.938 60.7578 164.397 62.1672 165.549 62.7532Z"
      fill="#DAC036"
    />
    <path
      d="M183.718 78.1239L183.723 78.1292C184.566 79.1093 186.044 79.2202 187.024 78.3769L199.532 67.6146C200.512 66.7713 200.623 65.2931 199.78 64.313L199.775 64.3077C198.932 63.3276 197.454 63.2167 196.474 64.06L183.966 74.8223C182.986 75.6656 182.875 77.1438 183.718 78.1239Z"
      fill="#DAC036"
    />
    <path
      d="M189.925 96.2823L189.926 96.2892C190.211 97.5563 191.469 98.3529 192.736 98.0685L208.906 94.4383C210.174 94.1538 210.97 92.8961 210.686 91.629L210.684 91.622C210.4 90.355 209.142 89.5584 207.875 89.8428L191.704 93.473C190.437 93.7574 189.64 95.0152 189.925 96.2823Z"
      fill="#DAC036"
    />
    <path
      d="M191.764 121.036V121.043C191.764 122.336 192.812 123.384 194.105 123.384H210.606C211.899 123.384 212.947 122.336 212.947 121.043V121.036C212.947 119.743 211.899 118.695 210.606 118.695H194.105C192.812 118.695 191.764 119.743 191.764 121.036Z"
      fill="#DAC036"
    />
    <path
      d="M108.849 43.9311L108.842 43.9344C107.69 44.5203 107.231 45.9297 107.817 47.0822L115.295 61.791C115.881 62.9436 117.29 63.4029 118.443 62.8169L118.449 62.8137C119.602 62.2277 120.061 60.8183 119.475 59.6658L111.997 44.957C111.411 43.8044 110.001 43.3452 108.849 43.9311Z"
      fill="#DAC036"
    />
    <path
      d="M84.2171 64.3696L84.2125 64.3749C83.3692 65.355 83.4801 66.8332 84.4601 67.6765L96.9681 78.4388C97.9481 79.2821 99.4263 79.1712 100.27 78.1911L100.274 78.1858C101.117 77.2057 101.007 75.7275 100.027 74.8842L87.5186 64.1219C86.5385 63.2786 85.0604 63.3895 84.2171 64.3696Z"
      fill="#DAC036"
    />
    <path
      d="M73.3346 92.7142L73.333 92.7211C73.0512 93.9829 73.8457 95.2343 75.1076 95.5162L91.2116 99.1129C92.4734 99.3947 93.7248 98.6002 94.0066 97.3384L94.0082 97.3315C94.29 96.0696 93.4955 94.8182 92.2337 94.5364L76.1297 90.9397C74.8678 90.6578 73.6164 91.4523 73.3346 92.7142Z"
      fill="#DAC036"
    />
    <path
      d="M71.0535 121.1V121.107C71.0535 122.4 72.1016 123.448 73.3946 123.448H89.8953C91.1883 123.448 92.2364 122.4 92.2364 121.107V121.1C92.2364 119.807 91.1883 118.759 89.8953 118.759H73.3946C72.1016 118.759 71.0535 119.807 71.0535 121.1Z"
      fill="#DAC036"
    />
    <path
      d="M139.03 227.093C139.03 227.093 119.509 240.135 107.372 215.253C107.372 215.253 131.186 205.407 139.03 227.093Z"
      fill="#FBDD40"
      stroke="#DAC036"
      strokeWidth="7"
      strokeMiterlimit="10"
    />
    <path
      d="M146.067 225.826C146.067 225.826 153.514 203.554 178.347 215.804C178.347 215.804 165.538 238.168 146.067 225.826Z"
      fill="#FBDD40"
      stroke="#DAC036"
      strokeWidth="7"
      strokeMiterlimit="10"
    />
  </svg>
);

export default InnovationIcon;
