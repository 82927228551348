import React from 'react';

const EngagementIcon = () => (
    <svg width="284" height="283" viewBox="0 0 284 283" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M142 282.386C220.424 282.386 284 219.172 284 141.193C284 63.2143 220.424 0 142 0C63.5756 0 0 63.2143 0 141.193C0 219.172 63.5756 282.386 142 282.386Z" fill="#FF6D6A" />
        <path d="M204.976 203.277L180.108 178.023H114.742C114.742 178.023 104.677 179.331 104.677 164.605V108.822C104.677 108.822 103.347 97.1129 116.446 97.1129H220.062C220.062 97.1129 230.452 95.7978 230.452 107.437V166.18C230.452 166.18 231.442 178.283 220.331 178.283H209.213L204.976 203.277Z" fill="#FDE6E6" />
        <path d="M79.0242 185.323L103.899 160.062H169.259C169.259 160.062 179.316 161.377 179.316 146.643V90.861C179.316 90.861 180.639 79.1517 167.54 79.1517H63.9379C63.9379 79.1517 53.548 77.8366 53.548 89.4756V148.219C53.548 148.219 52.5578 160.343 63.655 160.343H74.7663L79.0242 185.323Z" fill="#9B403E" />
        <path d="M143.04 101.586H92.6887C90.8996 101.586 89.4493 103.028 89.4493 104.807C89.4493 106.585 90.8996 108.027 92.6887 108.027H143.04C144.829 108.027 146.279 106.585 146.279 104.807C146.279 103.028 144.829 101.586 143.04 101.586Z" fill="#FDE6E6" />
        <path d="M143.04 116.017H92.6887C90.8996 116.017 89.4493 117.459 89.4493 119.238C89.4493 121.016 90.8996 122.458 92.6887 122.458H143.04C144.829 122.458 146.279 121.016 146.279 119.238C146.279 117.459 144.829 116.017 143.04 116.017Z" fill="#FDE6E6" />
        <path d="M143.04 130.454H119.678C117.889 130.454 116.439 131.896 116.439 133.675C116.439 135.454 117.889 136.896 119.678 136.896H143.04C144.829 136.896 146.279 135.454 146.279 133.675C146.279 131.896 144.829 130.454 143.04 130.454Z" fill="#FDE6E6" />
    </svg>
)

export default EngagementIcon;