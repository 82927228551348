import React from 'react';

const LogoIconWhite = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.04198 4.14599C3.88239 4.14266 3.72911 4.08307 3.60918 3.97774C3.48924 3.87241 3.41036 3.72811 3.38645 3.57029C3.36628 3.43766 3.38595 3.30203 3.44297 3.18059C3.49999 3.05916 3.59179 2.95739 3.70672 2.8882C3.82166 2.819 3.95455 2.7855 4.08855 2.79194C4.22255 2.79838 4.35162 2.84447 4.45939 2.92436C4.56716 3.00426 4.64878 3.11435 4.69389 3.2407C4.73901 3.36704 4.74558 3.50393 4.71279 3.63402C4.67999 3.7641 4.6093 3.88152 4.50969 3.97138C4.41007 4.06123 4.28601 4.11948 4.15325 4.13873C4.1164 4.144 4.0792 4.14643 4.04198 4.14599ZM4.04198 3.21713C4.00216 3.21641 3.96279 3.22552 3.92734 3.24366C3.8919 3.26181 3.86148 3.28842 3.83879 3.32114C3.81883 3.34741 3.80446 3.37749 3.79655 3.40952C3.78865 3.44155 3.78738 3.47486 3.79283 3.5074C3.79694 3.54056 3.80787 3.57251 3.82494 3.60124C3.84201 3.62996 3.86484 3.65484 3.892 3.6743C3.91869 3.694 3.94899 3.70824 3.98118 3.71623C4.01336 3.72421 4.04681 3.72578 4.07961 3.72084C4.1124 3.7159 4.1439 3.70454 4.1723 3.68743C4.20071 3.67031 4.22547 3.64777 4.24516 3.62109C4.26486 3.5944 4.2791 3.5641 4.28709 3.53192C4.29507 3.49973 4.29664 3.46628 4.2917 3.43349C4.28676 3.40069 4.2754 3.36919 4.25829 3.34079C4.24117 3.31238 4.21863 3.28762 4.19195 3.26793C4.14973 3.23516 4.09784 3.2173 4.04439 3.21713H4.04198Z"
      fill="#1B365D"
    />
    <path
      d="M6.14641 2.86639C5.99529 2.86571 5.84881 2.81414 5.73059 2.72001C5.61237 2.62588 5.5293 2.49467 5.49478 2.34754C5.46026 2.20042 5.47631 2.04596 5.54033 1.90907C5.60435 1.77218 5.71262 1.66084 5.84766 1.59302C5.98271 1.5252 6.13666 1.50484 6.28469 1.53523C6.43273 1.56562 6.56621 1.64499 6.66361 1.76053C6.76101 1.87608 6.81665 2.02106 6.82156 2.1721C6.82647 2.32314 6.78036 2.47143 6.69066 2.59306C6.62759 2.67795 6.54551 2.74688 6.451 2.79435C6.35648 2.84181 6.25217 2.86649 6.14641 2.86639ZM6.14641 1.93754H6.11013C6.07484 1.94816 6.04244 1.96671 6.01541 1.99177C5.98838 2.01682 5.96743 2.04773 5.95417 2.08212C5.94091 2.1165 5.93568 2.15347 5.93888 2.19019C5.94209 2.2269 5.95364 2.2624 5.97266 2.29398C5.99168 2.32555 6.01766 2.35235 6.04862 2.37235C6.07958 2.39235 6.1147 2.405 6.1513 2.40936C6.1879 2.41371 6.22501 2.40964 6.2598 2.39746C6.29459 2.38527 6.32613 2.36531 6.35202 2.33907C6.37231 2.31242 6.38684 2.28183 6.39469 2.24927C6.40254 2.2167 6.40354 2.18286 6.39763 2.14989C6.39172 2.11691 6.37902 2.08553 6.36034 2.05772C6.34166 2.02991 6.31741 2.00628 6.28913 1.98833C6.24853 1.95569 6.19847 1.93702 6.14641 1.93512V1.93754Z"
      fill="#1B365D"
    />
    <path
      d="M4.01537 14.2908C3.84682 14.2873 3.68564 14.2211 3.56332 14.1051C3.44101 13.9891 3.36635 13.8316 3.35395 13.6635C3.34156 13.4954 3.39231 13.3287 3.49628 13.196C3.60026 13.0633 3.74998 12.9741 3.91619 12.9459C4.09392 12.92 4.27471 12.9652 4.41932 13.0717C4.49095 13.124 4.55138 13.1901 4.59708 13.2662C4.64277 13.3422 4.6728 13.4266 4.6854 13.5144C4.69965 13.6107 4.69299 13.7089 4.66586 13.8023C4.63872 13.8958 4.59177 13.9823 4.52818 14.056C4.46459 14.1297 4.38587 14.1888 4.29738 14.2293C4.20888 14.2699 4.1127 14.2908 4.01537 14.2908ZM4.01537 13.362H3.97667C3.91018 13.3719 3.85025 13.4075 3.80976 13.4612C3.77077 13.5158 3.75431 13.5834 3.7638 13.6498C3.765 13.7028 3.78354 13.7539 3.81657 13.7952C3.8496 13.8366 3.89531 13.866 3.94667 13.879C3.99803 13.8919 4.0522 13.8876 4.10088 13.8667C4.14956 13.8459 4.19005 13.8096 4.21614 13.7635C4.23684 13.7371 4.25183 13.7067 4.26015 13.6743C4.26848 13.6418 4.26997 13.6079 4.26451 13.5748C4.2598 13.5419 4.24834 13.5103 4.23086 13.482C4.21338 13.4537 4.19025 13.4293 4.16292 13.4104C4.12105 13.379 4.07012 13.362 4.01779 13.362H4.01537Z"
      fill="#1B365D"
    />
    <path
      d="M7.68483 6.24317C7.53062 6.24343 7.38094 6.19106 7.26053 6.09471C7.14012 5.99837 7.0562 5.86381 7.02263 5.7133C6.98906 5.56279 7.00787 5.40532 7.07593 5.26695C7.14399 5.12857 7.25724 5.01756 7.39695 4.95228C7.53666 4.88699 7.69447 4.87134 7.84428 4.9079C7.99409 4.94447 8.12694 5.03107 8.22086 5.15338C8.31478 5.27569 8.36415 5.42639 8.36081 5.58056C8.35747 5.73474 8.30161 5.88315 8.20248 6.00128C8.08717 6.13936 7.92198 6.22629 7.74289 6.24317H7.68483ZM7.68483 5.31431C7.64788 5.31346 7.61123 5.32108 7.57768 5.33659C7.54414 5.35211 7.5146 5.3751 7.49132 5.40381C7.4685 5.42841 7.45105 5.45749 7.44008 5.4892C7.42911 5.5209 7.42485 5.55455 7.42758 5.58799C7.43031 5.62143 7.43997 5.65394 7.45594 5.68345C7.47191 5.71295 7.49384 5.73882 7.52035 5.75939C7.57162 5.80215 7.63766 5.823 7.70418 5.81744C7.73725 5.81546 7.76955 5.80671 7.79909 5.79173C7.82864 5.77675 7.85479 5.75586 7.87593 5.73036C7.89915 5.70573 7.91693 5.67649 7.92814 5.64454C7.93935 5.6126 7.94373 5.57866 7.94099 5.54491C7.93826 5.51117 7.92847 5.47837 7.91226 5.44865C7.89605 5.41893 7.87378 5.39294 7.8469 5.37237C7.8007 5.33837 7.7446 5.32052 7.68725 5.32157L7.68483 5.31431Z"
      fill="#1B365D"
    />
    <path
      d="M6.28913 14.8811L5.66989 14.7021L6.06659 13.3233C5.3827 12.9747 4.78137 12.4838 4.30302 11.8834C3.82467 11.2831 3.48039 10.5873 3.29333 9.84287C3.10627 9.09841 3.08077 8.32253 3.21853 7.5674C3.3563 6.81226 3.65414 6.09537 4.09203 5.46493C4.52993 4.83449 5.09772 4.30511 5.75725 3.91238C6.41677 3.51964 7.15273 3.27266 7.91565 3.18805C8.67857 3.10343 9.45077 3.18313 10.1803 3.4218C10.9099 3.66046 11.5799 4.05255 12.1453 4.57172C12.2082 4.62493 13.9522 6.12223 13.824 8.20006L13.1806 8.15894C13.2894 6.4125 11.7292 5.07001 11.7171 5.05792C11.213 4.59034 10.613 4.23826 9.95886 4.02621C9.30476 3.81417 8.6123 3.74723 7.9297 3.83008C7.2471 3.91293 6.59076 4.14357 6.00638 4.50594C5.422 4.8683 4.92363 5.35369 4.54596 5.9283C4.16829 6.50291 3.92041 7.15293 3.81957 7.83311C3.71873 8.51328 3.76736 9.20727 3.96206 9.86673C4.15677 10.5262 4.49288 11.1353 4.94699 11.6516C5.4011 12.168 5.96229 12.5791 6.59149 12.8564C6.66249 12.8879 6.71986 12.9437 6.75315 13.0139C6.78644 13.084 6.79346 13.1637 6.77291 13.2386L6.28913 14.8811Z"
      fill="#1B365D"
    />
    <path
      d="M12.2735 5.43042C13.108 5.55136 14.3755 6.48264 14.1167 8.77333C13.8748 10.8633 12.4501 12.5904 11.5357 13.2096C10.9382 13.6135 9.55464 14.1771 8.68384 13.5386C8.38698 13.3237 8.15137 13.035 8.00033 12.7011C7.8493 12.3672 7.78807 11.9997 7.82271 11.6349C7.85477 11.2639 7.96513 10.9039 8.14651 10.5787C8.32788 10.2535 8.57615 9.9704 8.87493 9.74815C10.1376 8.7564 10.0554 8.03557 10.0578 7.74288C10.0626 7.14542 10.4641 5.17402 12.2735 5.43042Z"
      fill="#A5DCE8"
    />
    <path
      d="M9.65623 14.0707C9.25711 14.0828 8.86488 13.9648 8.5387 13.7345C8.20582 13.4971 7.9412 13.1764 7.77138 12.8045C7.60155 12.4326 7.5325 12.0226 7.57114 11.6155C7.60561 11.2107 7.72539 10.8178 7.92258 10.4626C8.11978 10.1074 8.38993 9.79796 8.71528 9.55464C9.81346 8.69351 9.80862 8.08879 9.80378 7.79852V7.74047C9.80378 7.3607 9.97553 6.24317 10.7133 5.607C10.9301 5.42365 11.1849 5.29077 11.4593 5.21798C11.7337 5.14519 12.0209 5.1343 12.3001 5.18612C13.2676 5.32399 14.6222 6.39556 14.3537 8.79995C14.1118 11.0036 12.5734 12.7984 11.6639 13.4104C11.0689 13.8125 10.3738 14.0411 9.65623 14.0707ZM11.9614 5.65538C11.6252 5.647 11.2978 5.76332 11.0423 5.98193C10.4448 6.49474 10.3166 7.4502 10.3021 7.74531V7.79369C10.3021 8.13475 10.3021 8.93057 9.0249 9.94166C8.75376 10.1401 8.52746 10.3935 8.36075 10.6852C8.19404 10.9769 8.09066 11.3005 8.05734 11.6349V11.6688C8.0283 11.9894 8.08356 12.3121 8.21763 12.6049C8.3517 12.8976 8.55994 13.1503 8.82171 13.3378C9.54738 13.87 10.781 13.4152 11.3906 13.004C12.7448 11.9659 13.6362 10.4367 13.8724 8.74673C14.1143 6.53586 12.9048 5.77149 12.2396 5.67473C12.1475 5.66131 12.0545 5.65484 11.9614 5.65538Z"
      fill="#1B365D"
    />
    <path
      d="M1.96414 10.5319C1.89232 10.5333 1.8222 10.51 1.76543 10.466C1.70866 10.422 1.66867 10.3599 1.6521 10.29C1.34879 9.10896 1.35553 7.86963 1.67166 6.69198C1.98779 5.51432 2.60261 4.43823 3.4566 3.56787L3.91619 4.01779C3.1805 4.7675 2.63939 5.68581 2.34004 6.69264C2.04069 7.69946 1.99219 8.76424 2.19877 9.79411L3.48079 9.37806L3.67914 9.99246L2.06332 10.5174C2.03108 10.5267 1.99771 10.5316 1.96414 10.5319Z"
      fill="#1B365D"
    />
    <path
      d="M8.5 15.597C6.95262 15.5985 5.4482 15.0882 4.22097 14.1457L4.61042 13.6329C5.72705 14.4863 7.0946 14.9464 8.5 14.9415C9.42073 14.9467 10.3318 14.754 11.1716 14.3765C12.0114 13.9989 12.7602 13.4453 13.3675 12.7532C13.9747 12.061 14.4261 11.2465 14.6912 10.3647C14.9562 9.48298 15.0287 8.55454 14.9038 7.64232C14.7788 6.73009 14.4593 5.85536 13.9669 5.07736C13.4744 4.29936 12.8206 3.63624 12.0496 3.13291C11.2786 2.62958 10.4084 2.29778 9.49806 2.15999C8.58768 2.0222 7.65832 2.08162 6.77291 2.33424L6.59875 1.71258C7.57139 1.44063 8.591 1.38014 9.58898 1.53517C10.587 1.6902 11.5402 2.05716 12.3845 2.61137C13.2288 3.16558 13.9446 3.89418 14.4838 4.74815C15.023 5.60213 15.373 6.56167 15.5104 7.56224C15.6478 8.5628 15.5692 9.58118 15.2801 10.5489C14.991 11.5166 14.498 12.4111 13.8343 13.1723C13.1706 13.9336 12.3515 14.5438 11.4323 14.9621C10.513 15.3803 9.51479 15.5968 8.50484 15.597H8.5Z"
      fill="#1B365D"
    />
    <path
      d="M4.49189 11.6107L4.11212 11.0785L5.53927 10.0384C5.33214 9.62912 5.21231 9.18126 5.18738 8.72321C5.16244 8.26517 5.23294 7.80694 5.3944 7.37758C5.55587 6.94821 5.80478 6.55708 6.12536 6.22897C6.44594 5.90086 6.83119 5.64293 7.25669 5.47154L7.49858 6.06901C7.13564 6.21318 6.80889 6.43548 6.54152 6.72012C6.27415 7.00476 6.07271 7.34477 5.9515 7.716C5.8303 8.08724 5.7923 8.48061 5.84019 8.86818C5.88809 9.25576 6.02072 9.62804 6.22866 9.95859C6.27582 10.0282 6.29388 10.1135 6.27896 10.1962C6.26404 10.2789 6.21733 10.3525 6.14883 10.4013L4.49189 11.6107Z"
      fill="#1B365D"
    />
  </svg>
);

export default LogoIconWhite;
