import React from 'react';

const Compass = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="344"
      height="374"
      fill="none"
      viewBox="0 0 344 374"
    >
      <ellipse
        fill="#A1A1A1"
        rx="121.568"
        ry="122.098"
        transform="rotate(-47.847 313.461 -92.945) skewX(-.073)"
      ></ellipse>
      <ellipse
        fill="#EEE"
        rx="98.672"
        ry="100.295"
        transform="rotate(-47.847 313.83 -93.778) skewX(-.073)"
      ></ellipse>
      <path
        stroke="#A1A1A1"
        strokeWidth="7"
        d="M189.233 23.639c11.412 10.356 12.347 27.803 2.246 38.962-10.1 11.158-27.533 11.935-38.945 1.578-11.412-10.357-12.347-27.804-2.246-38.962 10.101-11.158 27.534-11.935 38.945-1.578z"
      ></path>
      <path
        stroke="#A1A1A1"
        strokeWidth="8"
        d="M0 -4L39.826 -4"
        transform="rotate(-89.821 139.629 -42.589)"
      ></path>
      <path
        stroke="#102037"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M173.124 120.328v35.53m0 90.191v38.81m51.713-86.365H258m-133.778 0H86m126.471-32.251l23.607-18.038m-101.176 87.458l-20.235 19.678m97.804-19.678l23.607 19.678m-101.176-89.098l-26.98-25.691"
      ></path>
      <path
        fill="#E56138"
        d="M158.72 206.029l-60.397-63.274 78.447 39.202-18.05 24.072z"
      ></path>
      <path
        fill="#52C2C2"
        d="M186.19 190.756l57.042 60.001-74.089-37.175 17.047-22.826z"
      ></path>
      <path
        fill="#A1A1A1"
        d="M184.791 185.139c6.54 5.936 6.6 16.538.134 23.682-6.467 7.143-17.011 8.122-23.551 2.187-6.54-5.936-6.6-16.539-.134-23.682 6.467-7.143 17.011-8.122 23.551-2.187z"
      ></path>
      <path
        stroke="#A1A1A1"
        d="M184.791 185.139c6.54 5.936 6.6 16.538.134 23.682-6.467 7.143-17.011 8.122-23.551 2.187-6.54-5.936-6.6-16.539-.134-23.682 6.467-7.143 17.011-8.122 23.551-2.187z"
      ></path>
    </svg>
);

export default Compass;