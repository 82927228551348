import React from 'react';

const LogoIconWhite = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99191 3.07966L2.8872 3.19067C2.96977 3.21978 3.05665 3.23479 3.1442 3.23507C3.27026 3.23631 3.39489 3.20831 3.50829 3.15328C3.6217 3.09825 3.7208 3.01768 3.79781 2.91791C3.87994 2.8079 3.93283 2.67888 3.95154 2.54289C3.97025 2.4069 3.95416 2.2684 3.90478 2.14031C3.85541 2.01222 3.77435 1.89874 3.66919 1.81047C3.56403 1.7222 3.4382 1.66202 3.30345 1.63556C3.16871 1.6091 3.02946 1.61722 2.89871 1.65916C2.76795 1.70109 2.64997 1.77548 2.55579 1.87537C2.4616 1.97527 2.39429 2.09739 2.36014 2.23035C2.32599 2.3633 2.32612 2.50274 2.36051 2.63563L2.43983 2.55L2.99191 3.07966ZM2.89989 2.24235C2.92879 2.2048 2.96573 2.1742 3.00801 2.1528C3.05029 2.13139 3.09682 2.11973 3.1442 2.11866C3.2095 2.11819 3.2731 2.13938 3.32505 2.17892C3.3779 2.21476 3.4187 2.26571 3.44212 2.3251C3.46553 2.3845 3.47047 2.44958 3.45629 2.51182C3.4421 2.57407 3.40946 2.63059 3.36262 2.67399C3.31578 2.71739 3.25693 2.74565 3.19376 2.75507C3.1306 2.7645 3.06606 2.75465 3.00858 2.72681C2.95111 2.69897 2.90338 2.65445 2.87163 2.59905C2.83988 2.54366 2.82559 2.47998 2.83063 2.41634C2.83568 2.3527 2.85982 2.29206 2.89989 2.24235Z"
      fill="white"
    />
    <path
      d="M6.206 0.310819H6.23139C6.206 0.288618 6.18062 0.260073 6.15207 0.237871C5.97866 0.110643 5.76198 0.0570646 5.54923 0.0888045C5.44382 0.103852 5.34245 0.139628 5.25096 0.194066C5.15947 0.248504 5.07967 0.320525 5.01619 0.405969C4.92666 0.525265 4.87186 0.666985 4.85787 0.815464C4.84387 0.963944 4.87122 1.1134 4.93689 1.24731C5.00256 1.38123 5.10399 1.49438 5.22997 1.57427C5.35596 1.65416 5.50158 1.69767 5.65076 1.7H5.7745C5.87944 1.68489 5.98034 1.64907 6.0713 1.59462C6.16227 1.54017 6.24149 1.46818 6.30436 1.38283C6.37354 1.28859 6.42119 1.18032 6.44397 1.06567H6.39637L6.206 0.310819ZM5.9141 1.06567C5.86522 1.12978 5.79374 1.17287 5.71421 1.18619C5.65641 1.18948 5.5988 1.17689 5.54764 1.14979C5.49647 1.1227 5.4537 1.08212 5.42396 1.03246C5.39422 0.982796 5.37864 0.925946 5.37891 0.868067C5.37918 0.810187 5.39528 0.753485 5.42549 0.704103C5.47437 0.639997 5.54585 0.596903 5.62538 0.58358H5.66979C5.73483 0.584661 5.79797 0.605698 5.85065 0.643842C5.9118 0.693679 5.95218 0.764505 5.96391 0.842499C5.97565 0.920492 5.95789 1.00006 5.9141 1.06567Z"
      fill="white"
    />
    <path
      d="M2.62702 15.2588C2.74722 15.3477 2.88971 15.4016 3.0387 15.4145C3.18768 15.4274 3.33732 15.3987 3.471 15.3317C3.43292 15.3063 3.39168 15.281 3.35678 15.2524L3.82318 14.6371L3.91202 14.7006C3.92315 14.6291 3.92315 14.5563 3.91202 14.4849C3.89652 14.3794 3.8603 14.2779 3.80543 14.1865C3.75056 14.095 3.67813 14.0153 3.59231 13.9519C3.5065 13.8885 3.40899 13.8427 3.3054 13.8172C3.20181 13.7916 3.09419 13.7868 2.98873 13.803C2.77627 13.8359 2.5854 13.9513 2.45765 14.1241C2.32989 14.297 2.27559 14.5133 2.30657 14.7259C2.32235 14.8314 2.35877 14.9328 2.41376 15.0242C2.46874 15.1156 2.54121 15.1953 2.62702 15.2588ZM2.86499 14.4246C2.91387 14.3605 2.98535 14.3174 3.06488 14.3041H3.10295C3.16799 14.3052 3.23112 14.3262 3.2838 14.3644C3.3458 14.4143 3.38675 14.4858 3.3985 14.5645C3.41026 14.6432 3.39197 14.7235 3.34726 14.7894C3.29729 14.8513 3.22582 14.8923 3.14706 14.904C3.0683 14.9158 2.988 14.8975 2.9221 14.8528C2.85797 14.8039 2.81486 14.7325 2.80153 14.653C2.79237 14.5715 2.81511 14.4897 2.86499 14.4246Z"
      fill="white"
    />
    <path
      d="M7.29744 5.54402L7.10072 5.63283C7.22659 5.71035 7.3717 5.75101 7.51953 5.75018C7.68701 5.75118 7.8507 5.70039 7.98819 5.60479C8.12568 5.50919 8.23024 5.37344 8.28756 5.21613C8.34488 5.05883 8.35215 4.88766 8.30838 4.72606C8.26461 4.56446 8.17194 4.42034 8.04305 4.31343C7.87794 4.17459 7.66483 4.10624 7.44973 4.12313C7.24442 4.14008 7.05339 4.23499 6.9159 4.38835C6.77841 4.54172 6.7049 4.74189 6.71046 4.94776C6.80882 4.90018 6.90718 4.85578 7.00871 4.81455L7.29744 5.54402ZM7.29744 4.74477C7.32245 4.71347 7.3537 4.68772 7.3892 4.66916C7.42471 4.65059 7.46369 4.63962 7.50367 4.63693H7.52905C7.59953 4.63806 7.66763 4.66261 7.7226 4.70671C7.77364 4.74538 7.81177 4.79857 7.832 4.85931C7.85223 4.92005 7.85359 4.98548 7.83592 5.04701C7.81825 5.10854 7.78236 5.16328 7.73298 5.20405C7.6836 5.24481 7.62304 5.26967 7.55926 5.27538C7.49547 5.28109 7.43145 5.26738 7.37562 5.23604C7.31978 5.20469 7.27473 5.1572 7.24641 5.09979C7.21808 5.04237 7.20779 4.97774 7.2169 4.91437C7.22601 4.85101 7.25408 4.79189 7.29744 4.74477Z"
      fill="white"
    />
    <path
      d="M6.41859 14.1455C6.44487 14.0557 6.43745 13.9595 6.39772 13.8748C6.35799 13.7901 6.28869 13.7228 6.20284 13.6856C5.32878 13.301 4.56516 12.7034 3.98185 11.9476L3.66457 12.1759L3.20768 11.5416L3.54717 11.2942C2.86724 10.1133 2.63433 8.7275 2.89088 7.38931C3.14743 6.05111 3.87637 4.84958 4.94489 4.00362C6.01341 3.15766 7.3504 2.72356 8.71228 2.78043C10.0741 2.8373 11.3703 3.38133 12.3645 4.31343L12.5073 4.44664C12.6959 4.43802 12.8849 4.44651 13.072 4.47201C13.3051 4.50937 13.5316 4.57986 13.7447 4.68134C13.4879 4.3355 13.1967 4.01669 12.8753 3.72985C11.9518 2.87877 10.8 2.31531 9.56105 2.10844C8.32209 1.90158 7.04968 2.06028 5.89955 2.56512C4.74943 3.06996 3.77149 3.89904 3.08545 4.95087C2.39941 6.00271 2.03503 7.23166 2.0369 8.48731C2.04077 8.86845 2.079 9.24847 2.15113 9.62276L2.46841 9.51809L2.70954 10.2539L2.32563 10.3776C2.5792 11.2103 2.99858 11.9832 3.55854 12.6498C4.1185 13.3163 4.8075 13.8629 5.58413 14.2565L5.22878 15.4839C5.46674 15.595 5.7047 15.6933 5.94901 15.7789L6.41859 14.1455Z"
      fill="white"
    />
    <path
      d="M15.2454 8.79178C14.9528 10.9069 13.8379 12.8214 12.1424 14.1201C11.4253 14.6022 9.76593 15.281 8.71889 14.5134C8.3648 14.2564 8.08319 13.9123 7.9014 13.5144C7.71961 13.1165 7.64382 12.6783 7.68137 12.2425C7.72118 11.7969 7.85484 11.3648 8.07358 10.9745C8.29231 10.5842 8.59116 10.2446 8.95051 9.97798C10.4671 8.78544 10.3688 7.92276 10.3719 7.5707C10.3719 6.87929 10.8288 4.66865 12.7992 4.77649C12.6849 4.64645 12.5866 4.54813 12.5104 4.47518C12.0158 4.49669 11.5429 4.68443 11.1683 5.00802C10.2768 5.75652 10.0832 7.09813 10.0832 7.55167V7.62145C10.0832 7.97033 10.0832 8.69664 8.77283 9.73059C8.38238 10.0229 8.05815 10.3944 7.82139 10.8207C7.58463 11.2471 7.44068 11.7186 7.39899 12.2045C7.35375 12.6913 7.43651 13.1815 7.63908 13.6266C7.84165 14.0716 8.157 14.456 8.5539 14.7418C8.94646 15.0162 9.41724 15.1564 9.89601 15.1414C10.755 15.1089 11.5883 14.84 12.3042 14.3644C14.0678 13.0134 15.2291 11.0231 15.5373 8.8235C15.8038 6.47014 14.7695 5.17929 13.7447 4.70037C13.8924 4.89555 14.029 5.09885 14.154 5.30932C14.8647 5.89925 15.4485 6.98712 15.2454 8.79178Z"
      fill="white"
    />
    <path
      d="M12.5073 4.45933C12.5834 4.53227 12.6818 4.63059 12.796 4.76063C12.8721 4.76063 12.9483 4.76063 13.0308 4.77649C13.4458 4.8477 13.8338 5.0296 14.154 5.30298C14.029 5.09251 13.8924 4.8892 13.7447 4.69403C13.5316 4.59254 13.3051 4.52205 13.072 4.4847C12.8849 4.4592 12.6959 4.45071 12.5073 4.45933Z"
      fill="white"
    />
    <path
      d="M10.3688 7.55485C10.3688 7.9069 10.464 8.76959 8.94735 9.96212C8.588 10.2288 8.28915 10.5684 8.07042 10.9587C7.85168 11.349 7.71802 11.7811 7.67821 12.2267C7.63783 12.6651 7.71225 13.1064 7.89416 13.5073C8.07606 13.9083 8.35919 14.255 8.71573 14.5134C9.76277 15.281 11.4222 14.6022 12.1392 14.1201C13.8347 12.8214 14.9496 10.9069 15.2423 8.79179C15.4453 6.98712 14.8615 5.88974 14.1508 5.30298C14.3696 5.66995 14.5463 6.06041 14.6775 6.46698C14.9738 7.19049 15.0691 7.98054 14.9535 8.75373C14.6741 10.7837 13.6088 12.6229 11.9869 13.8759C11.2572 14.3675 9.76594 14.9131 8.90293 14.2756C8.58815 14.0505 8.33766 13.7472 8.17627 13.3956C8.01488 13.044 7.94819 12.6564 7.9828 12.2711V12.2425C8.01792 11.8391 8.13849 11.4478 8.33652 11.0945C8.53455 10.7413 8.8055 10.4342 9.13137 10.1937C10.6765 8.97891 10.6702 8.02425 10.667 7.61511V7.55485C10.667 7.2028 10.8352 6.05466 11.5522 5.43936C11.8599 5.1758 12.2545 5.03568 12.6596 5.04608C12.7657 5.0465 12.8717 5.05392 12.9769 5.06828L13.0498 5.08414C12.9578 4.96362 12.8658 4.85578 12.7833 4.76698C10.8257 4.6528 10.372 6.85074 10.3688 7.55485Z"
      fill="white"
    />
    <path
      d="M14.6807 6.46698C14.5494 6.06042 14.3728 5.66995 14.154 5.30299C13.8338 5.02961 13.4458 4.84771 13.0308 4.77649C12.9483 4.77649 12.8721 4.77649 12.796 4.76064C12.8785 4.85578 12.9705 4.96362 13.0625 5.0778C13.4234 5.16376 13.7586 5.33468 14.04 5.57632C14.3215 5.81796 14.5411 6.12332 14.6807 6.46698Z"
      fill="white"
    />
    <path
      d="M0.926407 10.0224C0.681836 8.81041 0.732302 7.55742 1.07355 6.36902C1.4148 5.18063 2.03683 4.09164 2.88722 3.19384C2.80651 3.16806 2.73046 3.12951 2.66195 3.07967C2.51167 2.96822 2.40505 2.8078 2.36053 2.62612C1.3596 3.66921 0.642082 4.95078 0.276127 6.3491C-0.0898283 7.74742 -0.0920646 9.21605 0.26963 10.6155C0.282294 10.6667 0.305386 10.7148 0.337464 10.7567C0.369542 10.7986 0.409918 10.8334 0.456069 10.859C0.50222 10.8846 0.553155 10.9004 0.605694 10.9054C0.658232 10.9105 0.711245 10.9046 0.76142 10.8882L2.32246 10.3808C2.24887 10.1321 2.19164 9.8789 2.15112 9.62276L0.926407 10.0224Z"
      fill="white"
    />
    <path
      d="M2.66195 3.07649C2.73046 3.12634 2.80651 3.16488 2.88722 3.19067L2.99193 3.07967L2.43985 2.53732L2.36053 2.62295C2.40505 2.80463 2.51167 2.96505 2.66195 3.07649Z"
      fill="white"
    />
    <path
      d="M2.4684 9.5181L2.15112 9.62276C2.19489 9.87805 2.2553 10.1302 2.33197 10.3776L2.71588 10.2539L2.4684 9.5181Z"
      fill="white"
    />
    <path
      d="M3.76293 15.097C3.6869 15.2009 3.58671 15.2847 3.47102 15.3412C4.92397 16.4205 6.68659 17.0023 8.4968 17C9.61345 17 10.7192 16.7801 11.7508 16.353C12.7825 15.9258 13.7199 15.2997 14.5095 14.5104C15.2991 13.7211 15.9254 12.7841 16.3527 11.7528C16.7801 10.7215 17 9.61623 17 8.5C17 7.38376 16.7801 6.27846 16.3527 5.24719C15.9254 4.21592 15.2991 3.27889 14.5095 2.48959C13.7199 1.70029 12.7825 1.07419 11.7508 0.647024C10.7192 0.219859 9.61345 -1.66332e-08 8.4968 0C7.73094 0.00495644 6.96914 0.111611 6.23139 0.317164C6.3295 0.41129 6.40219 0.528702 6.44269 0.658468C6.48319 0.788234 6.49018 0.926134 6.46301 1.05933C7.63061 0.737346 8.85762 0.694527 10.0448 0.934333C11.2321 1.17414 12.3462 1.68984 13.2971 2.43974C14.248 3.18964 15.0091 4.1527 15.5187 5.25105C16.0284 6.34941 16.2723 7.55223 16.2309 8.76228C16.1894 9.97233 15.8637 11.1556 15.28 12.2165C14.6963 13.2775 13.8711 14.1862 12.8711 14.8693C11.8711 15.5524 10.7243 15.9906 9.5234 16.1487C8.32255 16.3067 7.10134 16.18 5.95853 15.7789L5.86017 16.1246L5.11773 15.9121L5.23829 15.4839C4.77521 15.2657 4.33426 15.0034 3.92157 14.7006C3.90627 14.8448 3.85136 14.982 3.76293 15.097Z"
      fill="white"
    />
    <path
      d="M6.23139 0.304474H6.20601L6.41542 1.04981H6.46301C6.49074 0.916134 6.48404 0.777606 6.44352 0.647232C6.40301 0.516857 6.33001 0.398913 6.23139 0.304474Z"
      fill="white"
    />
    <path
      d="M3.35681 15.2524C3.39171 15.281 3.43296 15.3063 3.47103 15.3317C3.58672 15.2752 3.6869 15.1914 3.76293 15.0875C3.84637 14.9742 3.89786 14.8405 3.91205 14.7006L3.82322 14.6371L3.35681 15.2524Z"
      fill="white"
    />
    <path
      d="M5.85064 16.1246L5.95851 15.7789C5.7142 15.6933 5.47624 15.595 5.23828 15.4839L5.11771 15.9121L5.85064 16.1246Z"
      fill="white"
    />
    <path
      d="M5.67295 10.7328C5.75145 10.6749 5.80541 10.5898 5.8242 10.4941C5.843 10.3984 5.82525 10.2991 5.77448 10.2159C5.53536 9.83423 5.3795 9.40646 5.31709 8.9605C5.25468 8.51454 5.28712 8.06043 5.4123 7.62787C5.53748 7.1953 5.75257 6.79401 6.04352 6.45023C6.33447 6.10645 6.69472 5.82791 7.10072 5.63284C7.06339 5.61221 7.0283 5.58777 6.99602 5.55989C6.9087 5.48586 6.8381 5.39414 6.78887 5.29081C6.73965 5.18748 6.71292 5.07487 6.71046 4.96045C6.24175 5.19446 5.82393 5.51877 5.48102 5.91474C5.13812 6.31071 4.87689 6.77053 4.71236 7.26776C4.54784 7.765 4.48325 8.28983 4.52232 8.8121C4.56139 9.33437 4.70334 9.84376 4.94002 10.311L3.56301 11.3132C3.69207 11.5336 3.83514 11.7455 3.99134 11.9476L5.67295 10.7328Z"
      fill="white"
    />
    <path
      d="M6.99604 5.55989C7.02831 5.58777 7.0634 5.61221 7.10074 5.63284L7.29746 5.54403L7.00873 4.82724C6.9072 4.86847 6.80884 4.91287 6.71048 4.96045C6.71294 5.07487 6.73966 5.18748 6.78889 5.29081C6.83811 5.39414 6.90872 5.48586 6.99604 5.55989Z"
      fill="white"
    />
    <path
      d="M3.68043 12.1854L3.99771 11.9571C3.84151 11.755 3.69844 11.5431 3.56938 11.3228L3.22989 11.5701L3.68043 12.1854Z"
      fill="white"
    />
    <path
      d="M13.0085 4.86847C14.0048 5.01119 15.5151 6.12127 15.2073 8.85205C14.9193 10.953 13.8133 12.8554 12.1297 14.1455C11.419 14.6244 9.76908 15.3 8.73156 14.5356C8.3776 14.2797 8.09659 13.936 7.91629 13.5382C7.736 13.1405 7.66267 12.7026 7.70356 12.2679C7.74337 11.8245 7.87712 11.3947 8.09595 11.007C8.31477 10.6193 8.61364 10.2825 8.97269 10.0192C10.4798 8.83936 10.3814 7.97985 10.3846 7.63097C10.3719 6.91418 10.851 4.56082 13.0085 4.86847Z"
      fill="white"
    />
  </svg>
);

export default LogoIconWhite;
