import React from 'react';

const LeafNode = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="81"
    fill="none"
    viewBox="0 0 66 81"
  >
    <path
      fill="#17AB6D"
      d="M37.768 65.927s-10.124 6.974-14.364-1.345c0 0 2.305 1.261 5.465-2.66 0 0-6.777 2.279-6.805-.091 0 0 .17-.423-2.028-3.779 0 0 4.934.491 5.393-1.265 0 0-5.643.366-6.6-1.211 0 0 .241-1.82-4.012-11.323 0 0 7.909 3.127 8.944.24 0 0-9.734-.706-10.838-3.743-1.103-3.037-.116-.624-.116-.624s1.824-8.208-1.503-11.184c0 0 1.478 1.493 4.705-2.292 3.227-3.784-.42-.476-.42-.476s-5.393 1.265-5.773-1.444c0 0 3.744-8.121.354-12.768 0 0 6.197.386 14.208 2.953 0 0 8.024 3.751 13.151 7.723 0 0-1.134 1.913-3.602 5.328 0 0 2.876 1.664 6.576-2.692 0 0 2.912.965 3.578 3.875 0 0-3.192 3.087-5.84 4.206 0 0 3.52.67 7.438-3.623 0 0 1.595 2.117 3.038 4.309l-3.206 1.901s1.715 1.208 4.01-.25c0 0 2.639 1.948 4.417 4.827 0 0-3.393 2.674-2.67 3.002 0 0 1.246.245 3.608-1.076 0 0 5.726 6.744 1.91 10.475-3.817 3.731-19.018 13.007-19.018 13.007z"
    ></path>
    <path
      fill="#045B33"
      d="M47.267 72.706l2.277-1.109S19.517 18.814 14.662 13.86l32.605 58.847z"
    ></path>
  </svg>
);

export default LeafNode;
