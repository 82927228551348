import React from "react";

function AboutRightFoot() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 321 275"
    >
      <path
        fill="#7C5939"
        d="M6.155 35.938c1.551 7.568 10.56 14.212 14.87 16.588 1.766-6.853 12.11-15.353 17.061-18.746-3.673-4.77-12.408-14.249-17.96-13.999-6.939.313-15.91 6.697-13.97 16.157zM47.29 39.742c-.032 9.542-9.3 19.093-13.93 22.676 16.443 19.856 32.903 21.14 43.382 17.88 5.512-1.714 9.595-6.874 9.156-12.63C84.63 51.052 60.86 42.253 47.289 39.742z"
      />
      <path
        fill="#7C5939"
        d="M127.705 13.616c7.627-1.234 17.033 4.832 20.784 8.02-5.781 4.082-10.059 16.768-11.475 22.6-5.764-1.742-17.724-6.547-19.459-11.826-2.169-6.598.618-17.251 10.15-18.794zM145.853 50.727c8.91-3.414 14.552-15.468 16.26-21.068 24.398 8.332 31.437 23.266 32.106 34.22.352 5.762-3.024 11.409-8.562 13.04-15.985 4.709-32.644-14.395-39.804-26.192z"
      />
      <path
        fill="#776759"
        d="M254.088 190.863c7.631 1.202 14.683 9.894 17.255 14.09-6.765 2.081-14.779 12.805-17.941 17.907-4.934-3.45-14.804-11.739-14.81-17.296-.008-6.946 5.957-16.202 15.496-14.701zM259.782 231.779c9.53-.471 18.645-10.17 22.011-14.96 20.592 15.512 22.633 31.895 19.859 42.513-1.459 5.586-6.425 9.901-12.195 9.728-16.657-.501-26.542-23.841-29.675-37.281z"
      />
      <path
        fill="#73665A"
        d="M162.807 138.815c-5.232 5.683-5.354 16.876-4.761 21.762 6.592-2.575 19.536.846 25.184 2.878 1.721-5.769 4.302-18.398.853-22.755-4.311-5.446-14.736-8.99-21.276-1.885zM183.775 174.407c-7.759 5.554-20.927 3.621-26.541 1.96-6.491 24.951 2.093 39.054 10.865 45.648 4.614 3.469 11.187 3.763 15.599.041 12.738-10.744 5.976-35.172.077-47.649z"
      />
    </svg>
  );
}

export default AboutRightFoot;
