import React from 'react';

const SunsetScene = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 1015">
      <ellipse
        cx="757.045"
        cy="949.793"
        fill="#F5D340"
        rx="136.154"
        ry="133.128"
      ></ellipse>
      <path
        fill="#0C4124"
        d="M1489 804.654s-477-1.675-767.61 113.794C543.586 989.096 281.019 1165 281.019 1165H1489V804.654z"
      ></path>
      <path
        fill="#386A3F"
        d="M-22 842.691s596.659-1.498 960.163 101.782C1160.57 1007.66 1489 1165 1489 1165H-22V842.691z"
      ></path>
      <path
        fill="#0C4124"
        d="M1372.77 866.912c13.42-.816 26.62-27.702 31.55-41.043 20.56-43.592 56.5-9.177 58.1 0 1.28 7.342 17.17 39.429 24.95 54.554-7.34 19.578-13.71 5.779-15.97-3.569-1.91 21.21-14.91 11.217-21.16 3.569-10.38 25.289-23.23 8.158-28.35-3.569-10.7 22.434-18.3 7.478-20.77-2.804-26.35 24.065-29.88 5.269-28.35-7.138z"
      ></path>
      <path
        fill="#24562B"
        d="M1378.08 834.214c10.71 1.428 23.12-21.333 27.98-32.892 27.99-53.544 59.77-6.629 58.97 6.885-.64 10.811 15.46 27.622 23.59 34.676-7.2 13.259-11.93-.255-13.39-8.669-2.88 20.806-10.66 8.669-14.2 0-5.75 26.313-14.39 10.964-17.99 0-4 26.313-15.52 10.964-20.79 0-7.83 26.313-14.06 10.964-16.19 0-18.87 23.253-26.52 9.689-27.98 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M1432.17 759.193c-8.15 23.649-30.22 37.206-41.02 41.029 2.24 12.844 21.19-.17 27.72-3.823-.32 14.88 17.1 10.958 17.1 0 10.2 13.524 13.55 9.429 18.99 0 5.4 13.507 18.72 12.657 26.19 3.823-17.91 0-30.79-27.353-34.98-41.029-1.27-9.853-5.84-23.649-14 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M1312.78 860.998c9.02-.548 17.89-18.612 21.2-27.576 13.82-29.288 37.96-6.166 39.03 0 .86 4.933 11.54 26.491 16.77 36.654-4.93 13.154-9.21 3.882-10.73-2.398-1.29 14.25-10.02 7.536-14.22 2.398-6.97 16.99-15.6 5.481-19.05-2.398-7.19 15.072-12.29 5.024-13.95-1.884-17.7 16.168-20.07 3.539-19.05-4.796z"
      ></path>
      <path
        fill="#24562B"
        d="M1316.35 839.029c7.2.959 15.53-14.333 18.8-22.099 18.8-35.975 40.16-4.454 39.62 4.625-.43 7.264 10.39 18.559 15.85 23.298-4.84 8.908-8.02-.171-9-5.824-1.93 13.979-7.16 5.824-9.54 0-3.86 17.679-9.67 7.366-12.08 0-2.69 17.679-10.43 7.366-13.97 0-5.27 17.679-9.45 7.366-10.88 0-12.68 15.623-17.82 6.51-18.8 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M1352.69 788.624c-5.48 15.889-20.3 24.998-27.56 27.566 1.51 8.63 14.24-.114 18.62-2.568-.21 9.998 11.5 7.362 11.5 0 6.84 9.086 9.1 6.335 12.75 0 3.63 9.075 12.58 8.504 17.6 2.568-12.04 0-20.69-18.377-23.51-27.566-.85-6.62-3.92-15.889-9.4 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M893.575 879.194c9.853-.6 19.55-20.344 23.167-30.141 15.102-32.013 41.493-6.74 42.666 0 .939 5.391 12.61 28.955 18.328 40.063-5.396 14.377-10.068 4.243-11.73-2.621-1.407 15.576-10.947 8.237-15.542 2.621-7.624 18.571-17.056 5.99-20.82-2.621-7.859 16.474-13.44 5.491-15.248-2.06-19.354 17.673-21.945 3.869-20.821-5.241z"
      ></path>
      <path
        fill="#24562B"
        d="M897.47 855.181c7.868 1.048 16.98-15.667 20.552-24.155 20.552-39.322 43.893-4.868 43.306 5.056-.47 7.939 11.353 20.285 17.323 25.465-5.285 9.737-8.759-.187-9.836-6.366-2.114 15.279-7.829 6.366-10.423 0-4.228 19.324-10.57 8.051-13.212 0-2.936 19.324-11.401 8.051-15.267 0-5.755 19.324-10.325 8.051-11.891 0-13.858 17.077-19.476 7.115-20.552 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M937.198 800.087c-5.989 17.367-22.197 27.324-30.124 30.131 1.644 9.432 15.557-.125 20.353-2.807-.235 10.927 12.56 8.047 12.56 0 7.487 9.931 9.948 6.924 13.945 0 3.964 9.918 13.75 9.294 19.23 2.807-13.152 0-22.606-20.087-25.688-30.131-.93-7.236-4.287-17.367-10.276 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M947.978 897.699c7.966-.484 15.806-16.448 18.73-24.369 12.21-25.883 33.552-5.449 34.492 0 .76 4.359 10.2 23.411 14.82 32.392-4.36 11.624-8.14 3.43-9.48-2.119-1.14 12.593-8.852 6.659-12.567 2.119-6.164 15.015-13.79 4.843-16.833-2.119-6.354 13.319-10.867 4.439-12.329-1.665-15.648 14.288-17.742 3.128-16.833-4.239z"
      ></path>
      <path
        fill="#24562B"
        d="M951.127 878.285c6.362.848 13.728-12.667 16.616-19.53 16.617-31.792 35.487-3.936 35.017 4.088-.38 6.419 9.18 16.401 14 20.589-4.27 7.872-7.08-.151-7.95-5.147-1.71 12.353-6.33 5.147-8.43 0-3.415 15.623-8.543 6.51-10.679 0-2.374 15.623-9.218 6.51-12.344 0-4.652 15.623-8.348 6.51-9.614 0-11.204 13.807-15.746 5.753-16.616 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M983.247 833.741c-4.842 14.042-17.946 22.091-24.355 24.361 1.329 7.626 12.578-.101 16.455-2.27-.19 8.835 10.155 6.507 10.155 0 6.053 8.03 8.043 5.599 11.275 0 3.205 8.02 11.113 7.515 15.553 2.27-10.64 0-18.282-16.24-20.775-24.361-.751-5.85-3.465-14.041-8.308 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M1385.35 906.572c7.97-.484 15.81-16.448 18.73-24.369 12.21-25.883 33.55-5.449 34.5 0 .76 4.359 10.2 23.41 14.82 32.391-4.36 11.625-8.14 3.431-9.49-2.119-1.13 12.593-8.85 6.66-12.56 2.119-6.17 15.015-13.79 4.844-16.83-2.119-6.36 13.32-10.87 4.44-12.33-1.665-15.65 14.289-17.75 3.128-16.84-4.238z"
      ></path>
      <path
        fill="#24562B"
        d="M1388.5 887.157c6.36.848 13.73-12.666 16.62-19.529 16.62-31.792 35.49-3.936 35.01 4.088-.38 6.418 9.18 16.4 14.01 20.589-4.27 7.872-7.08-.152-7.95-5.148-1.71 12.354-6.33 5.148-8.43 0-3.42 15.624-8.55 6.51-10.68 0-2.38 15.624-9.22 6.51-12.35 0-4.65 15.624-8.35 6.51-9.61 0-11.21 13.807-15.75 5.753-16.62 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M1420.62 842.614c-4.84 14.041-17.94 22.091-24.35 24.361 1.33 7.626 12.58-.101 16.45-2.27-.19 8.835 10.16 6.506 10.16 0 6.05 8.029 8.04 5.598 11.27 0 3.21 8.019 11.12 7.515 15.55 2.27-10.63 0-18.28-16.241-20.77-24.361-.75-5.851-3.46-14.042-8.31 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M65.716 834.23c11.736-.713 23.286-24.232 27.594-35.902 17.989-38.133 49.426-8.028 50.823 0 1.118 6.422 15.02 34.49 21.831 47.721-6.427 17.126-11.992 5.055-13.971-3.122-1.677 18.553-13.041 9.812-18.513 3.122-9.082 22.121-20.318 7.136-24.8-3.122-9.362 19.624-16.01 6.541-18.164-2.453-23.054 21.051-26.14 4.609-24.8-6.244z"
      ></path>
      <path
        fill="#24562B"
        d="M70.355 805.627c9.372 1.249 20.225-18.661 24.48-28.772 24.481-46.838 52.285-5.799 51.585 6.022-.559 9.457 13.523 24.163 20.634 30.333-6.295 11.599-10.434-.223-11.716-7.583-2.518 18.2-9.326 7.583-12.415 0-5.036 23.018-12.59 9.591-15.738 0-3.497 23.018-13.581 9.591-18.186 0-6.854 23.018-12.298 9.591-14.163 0-16.508 20.341-23.2 8.476-24.481 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M117.677 740.002c-7.134 20.687-26.44 32.546-35.882 35.89 1.958 11.235 18.531-.148 24.243-3.344-.28 13.017 14.961 9.586 14.961 0 8.918 11.83 11.85 8.248 16.612 0 4.721 11.815 16.378 11.072 22.906 3.344-15.667 0-26.928-23.926-30.6-35.89-1.107-8.619-5.106-20.687-12.24 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M-9.496 834.483c9.764-.593 19.372-20.157 22.955-29.865 14.965-31.721 41.116-6.679 42.278 0 .93 5.342 12.494 28.691 18.16 39.697-5.346 14.247-9.976 4.205-11.623-2.597-1.394 15.434-10.847 8.162-15.4 2.597-7.554 18.402-16.9 5.936-20.63-2.597-7.787 16.324-13.317 5.442-15.11-2.04-19.177 17.511-21.743 3.833-20.63-5.195z"
      ></path>
      <path
        fill="#24562B"
        d="M-5.637 810.69c7.797 1.039 16.825-15.524 20.365-23.935 20.365-38.963 43.493-4.824 42.911 5.01-.465 7.867 11.25 20.1 17.165 25.233-5.237 9.648-8.68-.185-9.746-6.308-2.095 15.14-7.758 6.308-10.328 0-4.19 19.147-10.473 7.978-13.092 0-2.909 19.147-11.297 7.978-15.128 0-5.702 19.147-10.23 7.978-11.782 0-13.732 16.921-19.298 7.05-20.365 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M33.729 756.099c-5.934 17.209-21.994 27.074-29.849 29.856 1.63 9.346 15.415-.124 20.167-2.782-.233 10.828 12.446 7.974 12.446 0 7.418 9.841 9.857 6.861 13.818 0 3.927 9.828 13.624 9.21 19.054 2.782-13.032 0-22.4-19.904-25.454-29.856-.921-7.17-4.247-17.209-10.182 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M31.412 843.297c7.94-.483 15.755-16.395 18.67-24.29 12.17-25.799 33.439-5.432 34.384 0 .756 4.345 10.162 23.334 14.77 32.286-4.348 11.587-8.114 3.42-9.453-2.112-1.134 12.552-8.822 6.638-12.525 2.112-6.144 14.966-13.746 4.828-16.778-2.112-6.334 13.277-10.832 4.425-12.29-1.66-15.596 14.243-17.684 3.118-16.778-4.224z"
      ></path>
      <path
        fill="#24562B"
        d="M34.55 823.945c6.342.845 13.684-12.625 16.563-19.466 16.563-31.689 35.374-3.923 34.9 4.074-.378 6.398 9.15 16.348 13.96 20.523-4.259 7.847-7.058-.151-7.926-5.131-1.704 12.314-6.31 5.131-8.4 0-3.407 15.573-8.518 6.489-10.647 0-2.366 15.573-9.189 6.489-12.304 0-4.638 15.573-8.321 6.489-9.583 0-11.168 13.762-15.695 5.734-16.563 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M66.567 779.546c-4.827 13.996-17.888 22.019-24.277 24.282 1.325 7.601 12.538-.101 16.402-2.263-.189 8.807 10.123 6.486 10.123 0 6.033 8.004 8.016 5.581 11.238 0 3.194 7.994 11.08 7.491 15.497 2.263-10.6 0-18.218-16.188-20.702-24.282-.75-5.832-3.454-13.996-8.281 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M124.591 846.385c7.941-.482 15.755-16.394 18.67-24.29 12.17-25.799 33.439-5.431 34.385 0 .756 4.345 10.161 23.335 14.77 32.286-4.349 11.587-8.114 3.42-9.453-2.112-1.135 12.553-8.823 6.639-12.525 2.112-6.145 14.967-13.746 4.828-16.779-2.112-6.334 13.277-10.832 4.426-12.289-1.659-15.597 14.242-17.685 3.118-16.779-4.225z"
      ></path>
      <path
        fill="#24562B"
        d="M127.73 827.034c6.341.845 13.684-12.626 16.563-19.466 16.562-31.689 35.373-3.924 34.9 4.074-.379 6.398 9.149 16.347 13.96 20.522-4.259 7.847-7.059-.151-7.927-5.13-1.703 12.313-6.309 5.13-8.399 0-3.408 15.572-8.518 6.488-10.648 0-2.366 15.572-9.188 6.488-12.304 0-4.637 15.572-8.321 6.488-9.582 0-11.169 13.762-15.696 5.734-16.563 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M159.746 782.634c-4.826 13.996-17.888 22.02-24.276 24.282 1.325 7.601 12.537-.1 16.402-2.262-.19 8.806 10.122 6.485 10.122 0 6.033 8.003 8.017 5.58 11.238 0 3.195 7.993 11.081 7.491 15.498 2.262-10.6 0-18.218-16.188-20.703-24.282-.749-5.831-3.454-13.996-8.281 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M285.883 875.969c34.165-2.077 67.786-70.539 80.327-104.511 52.365-111.002 143.876-23.369 147.943 0 3.254 18.695 43.722 100.4 63.55 138.915-18.709 49.854-34.91 14.714-40.672-9.088-4.881 54.008-37.96 28.562-53.89 9.088-26.437 64.394-59.143 20.773-72.192-9.088-27.25 57.124-46.603 19.042-52.873-7.14-67.109 61.278-76.09 13.415-72.193-18.176z"
      ></path>
      <path
        fill="#24562B"
        d="M299.386 792.707c27.284 3.636 58.877-54.322 71.263-83.755 71.263-136.344 152.197-16.88 150.161 17.53-1.629 27.529 39.364 70.337 60.064 88.3-18.325 33.761-30.372-.65-34.104-22.075-7.33 52.979-27.148 22.075-36.141 0-14.66 67.003-36.649 27.918-45.812 0-10.18 67.003-39.533 27.918-52.938 0-19.953 67.003-35.801 27.918-41.23 0-48.052 59.212-67.53 24.672-71.263 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M437.14 601.676c-20.767 60.218-76.965 94.74-104.451 104.474 5.701 32.705 53.943-.433 70.57-9.734-.814 37.89 43.552 27.903 43.552 0 25.959 34.436 34.493 24.01 48.355 0 13.743 34.393 47.676 32.229 66.678 9.734-45.606 0-78.385-69.65-89.074-104.474-3.224-25.091-14.863-60.219-35.63 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M418.717 920.637c23.114-1.406 45.862-47.725 54.346-70.709 35.428-75.099 97.341-15.81 100.093 0 2.201 12.649 29.58 67.927 42.995 93.985-12.658 33.729-23.619 9.955-27.517-6.148-3.302 36.539-25.682 19.324-36.46 6.148-17.886 43.567-40.014 14.054-48.842-6.148-18.437 38.647-31.53 12.882-35.772-4.831-45.403 41.458-51.48 9.076-48.843-12.297z"
      ></path>
      <path
        fill="#24562B"
        d="M427.853 864.305c18.459 2.459 39.833-36.752 48.213-56.666 48.214-92.245 102.971-11.42 101.593 11.861-1.102 18.624 26.633 47.587 40.638 59.739-12.398 22.842-20.548-.439-23.074-14.934-4.959 35.843-18.367 14.934-24.451 0-9.918 45.332-24.796 18.888-30.995 0-6.887 45.332-26.747 18.888-35.816 0-13.499 45.332-24.221 18.888-27.895 0-32.509 40.06-45.688 16.692-48.213 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M521.052 735.06c-14.05 40.742-52.072 64.098-70.668 70.683 3.857 22.127 36.496-.292 47.745-6.585-.551 25.635 29.466 18.878 29.466 0 17.563 23.298 23.336 16.244 32.715 0 9.298 23.268 32.255 21.805 45.112 6.585-30.856 0-53.033-47.122-60.264-70.683-2.181-16.976-10.056-40.742-24.106 0z"
      ></path>
      <path
        fill="#0C4124"
        d="M189.405 872.59c23.115-1.405 45.862-47.724 54.346-70.708 35.428-75.1 97.341-15.81 100.093 0 2.201 12.649 29.581 67.927 42.995 93.985-12.658 33.729-23.618 9.955-27.517-6.149-3.302 36.54-25.682 19.324-36.46 6.149-17.886 43.567-40.014 14.054-48.842-6.149-18.436 38.648-31.53 12.883-35.772-4.831-45.403 41.459-51.48 9.077-48.843-12.297z"
      ></path>
      <path
        fill="#24562B"
        d="M198.541 816.258c18.459 2.46 39.834-36.751 48.214-56.665 48.213-92.245 102.97-11.421 101.593 11.86-1.102 18.625 26.632 47.587 40.637 59.74-12.398 22.842-20.548-.439-23.074-14.935-4.959 35.844-18.367 14.935-24.451 0-9.918 45.332-24.795 18.889-30.994 0-6.888 45.332-26.747 18.889-35.816 0-13.5 45.332-24.222 18.889-27.895 0-32.51 40.061-45.688 16.692-48.214 0z"
      ></path>
      <path
        fill="#386A3F"
        d="M291.74 687.014c-14.05 40.742-52.072 64.098-70.668 70.683 3.857 22.127 36.496-.292 47.745-6.585-.55 25.635 29.466 18.878 29.466 0 17.563 23.298 23.337 16.244 32.715 0 9.298 23.268 32.256 21.805 45.112 6.585-30.855 0-53.033-47.122-60.264-70.683-2.181-16.976-10.056-40.742-24.106 0z"
      ></path>
      <path
        stroke="#1B365D"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M1090.35 802.368s-32.73 49.892-43.59 49.033"
      ></path>
      <path
        fill="#102037"
        d="M1149.72 862.631l-39.74 4.848-.44-23.626 39.87 1.718.31 17.06z"
      ></path>
      <path
        fill="#1B365D"
        d="M1153.26 842.993s2.61 3.805-3.54 3.928c-6.15.123-33.41 2.086-33.41 2.086s-4.22-2.454-.87-2.761c3.29-.368 37.82-3.253 37.82-3.253z"
      ></path>
      <path
        fill="#6499C6"
        d="M1109.54 844.098l.44 22.583-47.2-4.848-.37-19.822 21.98-29.64 25.15 31.727zM1123.7 800.649l29.56 42.344-37.82 3.191-30.12-43.694 38.38-1.841z"
      ></path>
      <path
        fill="#B4EBEB"
        d="M1059.11 844.527l26.21-42.037 30.68 44.554s1.8 5.216-3.29 2.27c-5.09-2.945-23.6-23.504-25.65-29.518-2.05-6.014-3.91-.491-5.28 1.473s-11.61 18.104-19.31 22.645c0 0-4.66 4.787-3.36.613z"
      ></path>
      <path
        fill="#102037"
        d="M1085.76 825.872s2.54 22.338 23.41 40.748l-44.84-4.664c.06 0 20-23.688 21.43-36.084z"
      ></path>
      <path
        stroke="#1B365D"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M1090.35 802.368s42.04 69.652 60.18 72.353M1119.66 800.649s42.05 69.653 60.18 72.353"
      ></path>
      <path
        fill="#F89F84"
        d="M254.938 971.561c6.887.172 12.039 2.863 13.754 4.187l-12.646 19.554c-5.197-2.902-8.657-8.14-9.738-10.396l-22.838-14.723c-3.452-2.225-4.194-5.199-4.133-6.408.247-.964 1.49-3.701 4.486-6.936 2.995-3.236 6.766-1.348 8.277 0l22.838 14.722z"
      ></path>
      <path
        fill="#E56138"
        d="M251.171 969.108l3.226 2.079c-1.385 1.565-4.848 1.87-6.406 1.828-4.569-2.697-14.244-8.587-16.395-10.569-2.151-1.982-1.761-5.355-1.297-6.793l3.094 1.995c1.372-.159 2.02.867 2.173 1.4l1.778 1.146a1.87 1.87 0 012.107 1.359l1.712 1.103c1.529-.057 2.085.91 2.172 1.401l1.778 1.146c1.319-.193 1.954.825 2.107 1.358l1.778 1.146c1.371-.159 2.02.868 2.173 1.401zM242.403 982.203l3.194 2.058c.861-1.902-.262-5.164-.931-6.557-4.297-3.018-13.559-9.336-16.229-10.461-2.671-1.126-5.576.625-6.695 1.641l3.063 1.975c.411 1.307 1.605 1.468 2.151 1.386l1.76 1.134c.411 1.308 1.562 1.441 2.086 1.345l1.694 1.092c.567 1.408 1.67 1.511 2.151 1.387l1.76 1.134c.358 1.274 1.54 1.427 2.086 1.345l1.759 1.134c.411 1.307 1.605 1.469 2.151 1.387zM253.531 996.47l5.29 3.41c1.607-.309 1.579-1.535 1.364-2.109l2.131-3.296c1.659-.275 1.601-1.521 1.364-2.109l2.174-3.362c1.659-.275 1.601-1.521 1.364-2.109l2.047-3.164c1.64-.362 1.678-1.688 1.491-2.307l2.046-3.163c1.434-.272 1.507-1.52 1.365-2.11l-5.291-3.41c-1.159.075-1.392 1.437-1.364 2.109l-2.046 3.164c-1.227.18-1.506 1.613-1.492 2.307l-2.046 3.164c-1.313.199-1.542 1.621-1.492 2.307l-2.046 3.164c-1.261.233-1.435 1.503-1.364 2.109l-2.131 3.295c-1.314.2-1.457 1.49-1.364 2.11z"
      ></path>
      <path
        fill="#1B365D"
        d="M255.746 982.863c1.591-.529 1.906-1.783 1.782-2.843-.081-.703-.591-1.253-1.186-1.636l-.894-.576a2.455 2.455 0 00-3.391.73 2.447 2.447 0 00.729 3.386l.819.528c.64.413 1.418.651 2.141.411z"
      ></path>
      <path
        fill="#102037"
        d="M254.466 978.547l1.46.941-1.722 2.664-1.461-.942 1.723-2.663z"
      ></path>
      <path
        fill="#A1A1A1"
        d="M399.655 903.593l.041 1.141 4.293.262-.055-1.556-4.279.153z"
      ></path>
      <path
        fill="#EEE"
        d="M398.721 905.397l.074 2.075.029.829 6.544-.233-.081-2.282c-.052-1.452-.563-1.641-3.713-1.633-2.52.007-2.952.833-2.853 1.244z"
      ></path>
      <path
        fill="#A1A1A1"
        d="M396.676 907.645c-3.523.126-4.017 1.598-3.823 2.318 5.942.549 17.813 1.295 17.763-.116-.063-1.763-2.218-2.101-3.602-2.052-1.384.05-5.933-.307-10.338-.15z"
      ></path>
      <path
        fill="#EEE"
        d="M389.359 911.672l-.344 4.478 24.858.981-.159-4.46c-.041-1.141-.37-3.31-5.404-3.13-5.034.18-9.827.04-14.368-.109-3.634-.12-4.569 1.443-4.583 2.24z"
      ></path>
      <path
        fill="#AAEAC8"
        d="M409.212 915.123c-2.898 0-10.108-.054-15.76-.268-4.451-.168-5.724 2.018-5.848 3.844-.49 3.34-1.207 10.302-.159 11.428 1.311 1.407 1.425 4.622-.307 5.515-1.385.714-1.48 3.237-1.354 4.41-.328 8.457-1.052 26.289-1.324 29.954-.341 4.581 1.791 4.297 5.874 5.709 4.082 1.412 18.917.467 21.534-.354 2.093-.656 2.629-5.182 2.635-7.363.488-9.848 1.406-29.77 1.172-30.676-.292-1.132-3.224-2.065-1.207-5.564 2.017-3.499 1.225-8.04 1.31-12.716.068-3.741-4.349-4.171-6.566-3.919z"
      ></path>
      <path
        fill="#0C4124"
        stroke="#0C4124"
        d="M391.821 930.664c2.662.424 10.271 1.087 19.417.345-3.203.876-11.57 2.033-19.417-.345zM389.801 936.809c3.195.376 12.351 1.007 23.419.524-3.901.667-14.046 1.494-23.419-.524z"
      ></path>
      <path
        fill="#EEE"
        d="M398.145 903.647l-.037-1.037 7.299-.261.037 1.037-7.299.261z"
      ></path>
      <path
        fill="#AAB4FE"
        fillOpacity="0.88"
        d="M279.126 854.432c-19.415-16.43-8.089-30.586 0-35.61 3.698-1.988 10.566-.828 13.538 0h9.41c9.509-2.65 26.855-1.104 34.339 0l8.75.828 8.09-2.65c2.146.221 7.099 1.557 9.74 5.135 3.302 4.472 3.962 20.372 0 28.488-3.17 6.493-9.905 5.797-12.877 4.638-15.574 6.57-51.575 15.602-70.99-.829z"
      ></path>
      <path
        fill="#1B214E"
        stroke="#1B214E"
        d="M301.744 819.319h-8.585c-17.169 8.215-12.437 23.188-7.924 29.648l3.797-1.491c.33-1.491.99-6.36.99-13.913s7.815-12.643 11.722-14.244zM344.589 819.153h-8.584c-17.17 8.215-12.437 23.188-7.925 29.648l3.797-1.491c.33-1.49.991-6.36.991-13.913 0-7.552 7.814-12.643 11.721-14.244z"
      ></path>
      <path
        fill="#CCAD0D"
        d="M267 909.325h15.318l5.345 29.493c6.074-3.465 12.94.795-1.636 7.587-14.577 6.792-18.758-5.256-19.027-12.129v-24.951z"
      ></path>
      <path
        fill="#CCAD0D"
        d="M288.537 846.979c-7.5.993-21.106 14.925-15.535 62.71 5.571 47.785 12.678-21.896 15.535-62.71zM338.149 847.348c8.235.992 23.175 14.924 17.058 62.709-6.117 47.785-13.921-21.896-17.058-62.709z"
      ></path>
      <path
        fill="#F5D340"
        d="M273.976 886.233c-2.245-9.275 8.75-24.679 14.363-30.144l47.877-1.822 11.484 15.989a24.997 24.997 0 014.695 14.584v23.256l-4.292 37.93c-2.285 2.837-11.445 4.974-33.599 4.974-29.823 0-34.144-2.435-34.584-4.974l-5.944-33.789v-26.004z"
      ></path>
      <rect
        width="56.792"
        height="30.476"
        x="285.565"
        y="832.072"
        fill="#FFED8E"
        rx="15.238"
      ></rect>
      <rect
        width="16.16"
        height="7.938"
        x="307.367"
        y="869.18"
        fill="#F5D340"
        stroke="#CCAD0D"
        strokeWidth="2"
        rx="3.969"
      ></rect>
      <rect
        width="48.867"
        height="14.907"
        x="288.536"
        y="886.399"
        fill="#CFE8E8"
        rx="7.453"
      ></rect>
      <path
        fill="#CCAD0D"
        d="M343.426 909.325h22.416v24.828c-.391 6.812-6.464 18.733-27.589 12.149l-.255 1.817c-8.888-3.448-21.331-8.655 0-1.897l.255.08 5.173-36.977z"
      ></path>
      <rect
        width="72.971"
        height="44.389"
        x="277.31"
        y="902.631"
        fill="#B4EBEB"
        rx="22.194"
      ></rect>
      <rect
        width="69.971"
        height="17.869"
        x="278.81"
        y="904.131"
        fill="#CFE8E8"
        stroke="#B4EBEB"
        strokeWidth="3"
        rx="8.935"
      ></rect>
      <path
        stroke="#52C2C2"
        strokeWidth="2"
        d="M278.077 910.57c1.486-1.269 5.943-3.809 11.887-3.809h50.023"
      ></path>
      <rect
        width="4.943"
        height="2.313"
        x="292.338"
        y="905.781"
        fill="#52C2C2"
        stroke="#52C2C2"
        rx="1.156"
      ></rect>
      <path
        stroke="#52C2C2"
        d="M337.404 892.03c-1.07-.773-4.279-2.318-8.558-2.318h-36.017"
      ></path>
      <path
        fill="#52C2C2"
        stroke="#52C2C2"
        d="M325.019 889.218h-4.286a.494.494 0 100 .987h4.286a.494.494 0 100-.987z"
      ></path>
      <path
        stroke="#CCAD0D"
        strokeWidth="2"
        d="M329.203 849.485l6.39 6.839m0-6.625l-6.603 6.411"
      ></path>
      <path
        stroke="#CCAD0D"
        strokeWidth="2"
        d="M329.585 849.967H335.179V855.917H329.585z"
      ></path>
      <path
        stroke="#CCAD0D"
        strokeWidth="2"
        d="M294.841 850.485H300.435V856.4350000000001H294.841z"
      ></path>
      <path
        stroke="#CCAD0D"
        strokeWidth="2"
        d="M294.459 850.003l6.39 6.839m0-6.625l-6.603 6.411"
      ></path>
      <path
        fill="#7C5939"
        stroke="#7C5939"
        d="M328.819 877.621v-20.704h6.769v20.704c-2.774 5.697-5.668 2.374-6.769 0zM294.48 878.283v-20.704h6.769v20.704c-2.774 5.698-5.669 2.374-6.769 0z"
      ></path>
      <ellipse
        cx="297.782"
        cy="865.53"
        fill="#161615"
        rx="1.321"
        ry="1.325"
      ></ellipse>
      <ellipse
        cx="297.782"
        cy="874.142"
        fill="#161615"
        rx="1.321"
        ry="1.325"
      ></ellipse>
      <ellipse
        cx="297.782"
        cy="869.836"
        fill="#161615"
        rx="1.321"
        ry="1.325"
      ></ellipse>
      <ellipse
        cx="332.121"
        cy="865.53"
        fill="#161615"
        rx="1.321"
        ry="1.325"
      ></ellipse>
      <ellipse
        cx="332.121"
        cy="874.142"
        fill="#161615"
        rx="1.321"
        ry="1.325"
      ></ellipse>
      <ellipse
        cx="332.121"
        cy="869.836"
        fill="#161615"
        rx="1.321"
        ry="1.325"
      ></ellipse>
      <rect
        width="4.938"
        height="4.638"
        x="295.148"
        y="868.18"
        stroke="#E56138"
        strokeWidth="4.638"
        rx="2.319"
      ></rect>
      <path
        fill="#953621"
        stroke="#953621"
        d="M302.731 869.342h-4.286a.494.494 0 000 .988h4.286a.494.494 0 100-.988z"
      ></path>
      <rect
        width="4.938"
        height="4.638"
        x="329.487"
        y="868.18"
        stroke="#E56138"
        strokeWidth="4.638"
        rx="2.319"
      ></rect>
      <path
        fill="#953621"
        stroke="#953621"
        d="M337.071 869.342h-4.286a.494.494 0 100 .988h4.286a.494.494 0 000-.988z"
      ></path>
      <ellipse
        cx="357.215"
        cy="837.538"
        fill="#40518C"
        rx="6.934"
        ry="14.741"
      ></ellipse>
      <ellipse
        cx="357.71"
        cy="837.704"
        fill="#6A74C6"
        rx="3.797"
        ry="11.263"
      ></ellipse>
      <ellipse
        cx="358.371"
        cy="837.538"
        fill="#1B214E"
        rx="1.816"
        ry="5.135"
      ></ellipse>
      <path
        fill="#1B214E"
        stroke="#1B214E"
        d="M297.947 824.95v8.116h5.943v-7.122c0-2.385 2.752-2.65 4.127-2.484h13.703c3.302 0 5.118 1.656 5.613 2.484v7.619h5.448c0-1.711-.066-6.128-.33-10.103-.264-3.976-4.512-4.638-6.604-4.472H303.89c-5.283 0-6.163 3.975-5.943 5.962z"
      ></path>
      <defs>
        <filter
          id="filter0_d"
          width="1245"
          height="219.318"
          x="204"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="5" dy="5"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>    
);

export default SunsetScene;