import React from 'react';

const DaytimeScene = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 1024">
    <g opacity="0.5">
      <path
        d="M862 640l156.88-229.825 38.12 43.727 79.17-100.474 73.8 89.376 108.01-139.03 55.71 85.121 41.05-25.536L1642 640l-278.58-11.361.01.012-8.8-.371-183.27-7.718L862 640z"
        fill="#1B365D" />
      <path
        d="M976.087 464.341l36.453-53.694c2.66-3.913 8.35-4.119 11.29-.408l41.69 52.759a6.989 6.989 0 01.59 7.808l-4.73 8.296c-2.11 3.696-7 4.672-10.37 2.067l-17.63-13.648a6.993 6.993 0 00-9.64 1.034l-10.32 12.292a7.006 7.006 0 01-9.76.948l-9.022-7.277a7 7 0 00-7.062-1.022l-3.029 1.249c-6.419 2.646-12.36-4.66-8.46-10.404zM1120.52 414.817l-19.94-14.767c-3.21-2.373-3.78-6.942-1.27-10.038l32.38-39.88c2.91-3.581 8.43-3.42 11.12.324l37.59 52.248c2.1 2.917-1.48 6.538-4.42 4.473a3.186 3.186 0 00-4.54.93l-6.49 10.466c-2.47 3.97-8.05 4.461-11.17.982l-8.43-9.411a7.001 7.001 0 00-9.56-.818l-6.76 5.353a7 7 0 01-8.51.138zM1288.32 421.532l-27.25-37.145a7 7 0 01-1.35-4.14v-3.836c0-1.546.51-3.048 1.45-4.272l52.83-68.565c2.89-3.75 8.59-3.611 11.29.276l45.58 65.563a7.006 7.006 0 008.41 2.48l38.07-15.629a7 7 0 018.34 2.386l50.68 70.419a6.988 6.988 0 01-.44 8.731l-9.79 11.06a7.002 7.002 0 01-9.18 1.145l-28.84-19.638a7.01 7.01 0 00-9.64 1.713l-17.9 25.021c-2.41 3.372-7.21 3.92-10.32 1.178l-56.45-49.765a7 7 0 00-8.04-.862l-28.4 15.852a6.994 6.994 0 01-9.05-1.972z"
        fill="#EEE" />
      <path
        d="M976.087 464.341l36.453-53.694c2.66-3.913 8.35-4.119 11.29-.408l41.69 52.759a6.989 6.989 0 01.59 7.808l-4.73 8.296c-2.11 3.696-7 4.672-10.37 2.067l-17.63-13.648a6.993 6.993 0 00-9.64 1.034l-10.32 12.292a7.006 7.006 0 01-9.76.948l-9.022-7.277a7 7 0 00-7.062-1.022l-3.029 1.249c-6.419 2.646-12.36-4.66-8.46-10.404zM1120.52 414.817l-19.94-14.767c-3.21-2.373-3.78-6.942-1.27-10.038l32.38-39.88c2.91-3.581 8.43-3.42 11.12.324l37.59 52.248c2.1 2.917-1.48 6.538-4.42 4.473a3.186 3.186 0 00-4.54.93l-6.49 10.466c-2.47 3.97-8.05 4.461-11.17.982l-8.43-9.411a7.001 7.001 0 00-9.56-.818l-6.76 5.353a7 7 0 01-8.51.138zM1288.32 421.532l-27.25-37.145a7 7 0 01-1.35-4.14v-3.836c0-1.546.51-3.048 1.45-4.272l52.83-68.565c2.89-3.75 8.59-3.611 11.29.276l45.58 65.563a7.006 7.006 0 008.41 2.48l38.07-15.629a7 7 0 018.34 2.386l50.68 70.419a6.988 6.988 0 01-.44 8.731l-9.79 11.06a7.002 7.002 0 01-9.18 1.145l-28.84-19.638a7.01 7.01 0 00-9.64 1.713l-17.9 25.021c-2.41 3.372-7.21 3.92-10.32 1.178l-56.45-49.765a7 7 0 00-8.04-.862l-28.4 15.852a6.994 6.994 0 01-9.05-1.972z"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M16 636l162.914-248.532 39.586 47.287 82.218-108.653 76.638 96.652 112.159-150.347 57.857 92.049 42.632-27.615L826 636l-289.296-12.286.01.013-9.135-.401-190.32-8.346L16 636z"
        fill="#17331B" />
      <path
        d="M133.513 447.52l38.961-59.755c2.645-4.057 8.512-4.267 11.44-.409l43.735 57.627a7.001 7.001 0 01.563 7.594l-5.291 9.661c-2.094 3.824-7.137 4.823-10.532 2.087L193.9 449.422a6.999 6.999 0 00-9.842 1.056l-10.918 13.539a7 7 0 01-9.95.967l-9.501-7.978a7 7 0 00-7.263-1.071l-4.288 1.841c-6.366 2.733-12.409-4.452-8.625-10.256zM284.52 392.529l-21.301-16.422a7.002 7.002 0 01-1.246-9.849l34.224-43.888c2.905-3.727 8.593-3.563 11.279.325l39.385 57.006c2.138 3.094-1.679 6.811-4.715 4.592a3.338 3.338 0 00-4.838.987l-6.849 11.503c-2.447 4.109-8.203 4.609-11.321.983l-8.979-10.439a7 7 0 00-9.76-.837l-7.152 5.896a7 7 0 01-8.727.143zM458.817 399.896l-28.525-40.5a7.001 7.001 0 01-1.277-4.031v-4.679c0-1.499.481-2.958 1.373-4.163l55.131-74.52c2.883-3.896 8.758-3.754 11.449.277l47.579 71.263a7 7 0 008.573 2.55l39.641-16.945a7.001 7.001 0 018.51 2.456l53.072 76.787a7 7 0 01-.427 8.515l-10.635 12.503a7 7 0 01-9.381 1.174l-30.086-21.332a6.999 6.999 0 00-9.818 1.746l-18.818 27.388c-2.41 3.508-7.368 4.071-10.503 1.193l-58.781-53.963a7 7 0 00-8.251-.896l-29.585 17.198a7 7 0 01-9.241-2.021z"
        fill="#EEE" />
      <path
        d="M133.513 447.52l38.961-59.755c2.645-4.057 8.512-4.267 11.44-.409l43.735 57.627a7.001 7.001 0 01.563 7.594l-5.291 9.661c-2.094 3.824-7.137 4.823-10.532 2.087L193.9 449.422a6.999 6.999 0 00-9.842 1.056l-10.918 13.539a7 7 0 01-9.95.967l-9.501-7.978a7 7 0 00-7.263-1.071l-4.288 1.841c-6.366 2.733-12.409-4.452-8.625-10.256zM284.52 392.529l-21.301-16.422a7.002 7.002 0 01-1.246-9.849l34.224-43.888c2.905-3.727 8.593-3.563 11.279.325l39.385 57.006c2.138 3.094-1.679 6.811-4.715 4.592a3.338 3.338 0 00-4.838.987l-6.849 11.503c-2.447 4.109-8.203 4.609-11.321.983l-8.979-10.439a7 7 0 00-9.76-.837l-7.152 5.896a7 7 0 01-8.727.143zM458.817 399.896l-28.525-40.5a7.001 7.001 0 01-1.277-4.031v-4.679c0-1.499.481-2.958 1.373-4.163l55.131-74.52c2.883-3.896 8.758-3.754 11.449.277l47.579 71.263a7 7 0 008.573 2.55l39.641-16.945a7.001 7.001 0 018.51 2.456l53.072 76.787a7 7 0 01-.427 8.515l-10.635 12.503a7 7 0 01-9.381 1.174l-30.086-21.332a6.999 6.999 0 00-9.818 1.746l-18.818 27.388c-2.41 3.508-7.368 4.071-10.503 1.193l-58.781-53.963a7 7 0 00-8.251-.896l-29.585 17.198a7 7 0 01-9.241-2.021z"
      />
    </g>
    <path d="M1456 536.001s-258.25-.837-415.58 56.842C944.154 628.132 802 716 802 716h654V536.001z" fill="#0C4124" />
    <path d="M1456 536.001s-258.25-.837-415.58 56.842C944.154 628.132 802 716 802 716h654V536.001z" />
    <path
      d="M225.377 111.77c-24.122-.289-44.552 27.495-51.751 41.423-16.962-1.802-50.209-5.336-31.499 12.294 18.71 17.63 61.743 6.562 95.956-5.443 34.213-12.005 30.923 18.928 72.989 23.399 42.067 4.471 58.063 21.812 84.812 9.014 26.75-12.799 73.956 7.86 103.81 11.033 23.883 2.538 59.632-23.049 74.521-36.16-4.472-28.914-67.941-25.706-97.795-28.879-29.853-3.173-15.862-37.945-69.544-49.338-53.681-11.393-81.76 33.257-108.808 48.868-27.049 15.61-42.538-25.85-72.691-26.211z"
      fill="#fff" />
    <path
      d="M535.745 189.672c-34.509.912-14.379 27.727 0 41.021l15.893 12.534h105.57c11.352 0 59.029-12.534 82.868-20.51 23.838-7.977 61.299 0 93.084 20.51 31.784 20.51 43.136 0 84.002-12.534 40.866-12.534 44.272 0 87.408-7.976 43.14-7.977 4.54-26.208-17.027-26.208-21.568 0-55.624-27.347-91.949-28.487-36.325-1.139-71.516 21.65-102.165 28.487-30.65 6.837-62.435-38.742-97.625-39.881-35.19-1.14-40.866 19.371-69.245 33.044-28.379 13.674-47.677-1.139-90.814 0zM551.638 101.933c-21.569-10.255-15.893-12.534-55.624-15.952-11.805-1.165-50.326 20.784-68.11 31.905 22.704 4.558 73.105 13.673 93.084 13.673 24.974 0 47.677 6.837 79.462 11.395 25.428 3.646 63.569-15.193 79.462-25.068 0-8.356-8.4-25.068-42.001-25.068-42.002 0-64.705 19.371-86.273 9.115z"
      fill="#fff" />
    <path
      d="M535.745 189.672c-34.509.912-14.379 27.727 0 41.021l15.893 12.534h105.57c11.352 0 59.029-12.534 82.868-20.51 23.838-7.977 61.299 0 93.084 20.51 31.784 20.51 43.136 0 84.002-12.534 40.866-12.534 44.272 0 87.408-7.976 43.14-7.977 4.54-26.208-17.027-26.208-21.568 0-55.624-27.347-91.949-28.487-36.325-1.139-71.516 21.65-102.165 28.487-30.65 6.837-62.435-38.742-97.625-39.881-35.19-1.14-40.866 19.371-69.245 33.044-28.379 13.674-47.677-1.139-90.814 0zM551.638 101.933c-21.569-10.255-15.893-12.534-55.624-15.952-11.805-1.165-50.326 20.784-68.11 31.905 22.704 4.558 73.105 13.673 93.084 13.673 24.974 0 47.677 6.837 79.462 11.395 25.428 3.646 63.569-15.193 79.462-25.068 0-8.356-8.4-25.068-42.001-25.068-42.002 0-64.705 19.371-86.273 9.115z"
    />
    <path stroke="#BDBDBD" stroke-width="10"
      d="M260.782 193.021l-314.56 212.624M259.517 213.83L-57.781 451.324M255.3 247.496L-60.188 502.158M253.193 280.255L-64.313 563.846M252.056 303.641L17.627 589.018M249.553 341.368L21.783 646.256M306.2 193.02l314.56 212.624M307.465 213.83l317.297 237.493M311.682 247.497l315.488 254.66M313.79 280.255l317.505 283.591M314.926 303.642l234.43 285.377M317.428 341.367L545.2 646.255" />
    <path d="M251 164v89.5h66l-6-89.5-28-63.952L251 164z" fill="#C4C4C4" />
    <path d="M116.5 581L255 245.5h31L192.5 581h-76z" fill="#C4C4C4" />
    <path d="M453.5 581.5L315 246h-31l93.5 335.5h76z" fill="#C4C4C4" />
    <path d="M-28 580h544l934 123.06L1440 968H-28V580z" fill="url(#paint1_linear)" />
    <path d="M-28 580h544l934 123.06L1440 968H-28V580z" />
    <path
      d="M473.697 619.224c30.926-2.176 61.361-73.906 72.713-109.499 47.401-116.302 130.238-24.485 133.92 0 2.945 19.587 39.578 105.192 57.526 145.546-16.936 52.233-31.601 15.416-36.817-9.522-4.418 56.587-34.362 29.926-48.782 9.522-23.93 67.468-53.537 21.764-65.349-9.522-24.667 59.851-42.186 19.951-47.862-7.481-60.747 64.204-68.878 14.056-65.349-19.044z"
      fill="#0C4124" />
    <path
      d="M485.92 531.988c24.697 3.809 53.296-56.915 64.508-87.753 64.508-142.853 137.771-17.687 135.928 18.367-1.474 28.843 35.633 73.694 54.371 92.514-16.587 35.373-33.145-.68-36.524-23.128-6.635 55.508-18.922 23.128-27.062 0-13.271 70.202-33.176 29.25-41.47 0-9.215 70.202-35.787 29.25-47.92 0-18.063 70.202-32.408 29.25-37.323 0-43.497 62.039-61.129 25.849-64.508 0z"
      fill="#24562B" />
    <path
      d="M610.617 331.837c-18.799 63.093-69.671 99.263-94.551 109.462 5.16 34.266 48.83-.454 63.881-10.199-.737 39.699 39.424 29.236 39.424 0 23.498 36.08 31.224 25.156 43.771 0 12.441 36.034 43.157 33.768 60.359 10.199-41.284 0-70.956-72.975-80.632-109.462-2.918-26.289-13.454-63.093-32.252 0z"
      fill="#386A3F" />
    <path
      d="M-77.271 652.634c32.343-1.936 64.174-65.744 76.046-97.406 49.574-103.455 136.209-21.78 140.059 0 3.081 17.424 41.392 93.574 60.163 129.471-17.712 46.464-33.049 13.713-38.504-8.47-4.621 50.336-35.938 26.62-51.018 8.47-25.028 60.016-55.992 19.36-68.346-8.47-25.797 53.24-44.12 17.746-50.055-6.655-63.532 57.112-72.035 12.503-68.345-16.94z"
      fill="#0C4124" />
    <path
      d="M-64.488 575.032c25.83 3.389 55.74-50.628 67.465-78.06 67.466-127.074 144.087-15.733 142.159 16.338-1.542 25.657 37.267 65.555 56.864 82.296-17.348 31.466-28.753-.605-32.287-20.574-6.939 49.378-25.701 20.574-34.215 0-13.878 62.448-34.696 26.02-43.37 0-9.638 62.448-37.427 26.02-50.117 0-18.89 62.448-33.894 26.02-39.034 0-45.49 55.187-63.931 22.995-67.465 0z"
      fill="#24562B" />
    <path
      d="M65.925 396.989c-19.66 56.125-72.864 88.3-98.885 97.371 5.397 30.482 51.069-.403 66.81-9.071-.771 35.313 41.231 26.005 41.231 0 24.575 32.094 32.655 22.377 45.778 0 13.01 32.053 45.135 30.037 63.125 9.071-43.176 0-74.208-64.914-84.328-97.371-3.051-23.385-14.07-56.124-33.73 0z"
      fill="#386A3F" />
    <g opacity=".75">
      <path fill="#7C5939" d="M1403.24 526.986h15.637v13.292h-15.637z" />
      <path
        d="M1382.64 527.076c6.55-.788 12.24-14.01 14.26-20.523 8.81-21.415 27.41-6.056 28.46-1.723.84 3.466 9.55 18.308 13.8 25.295-3.03 9.562-6.55 3.165-7.93-1.229-.32 10.179-6.98 5.795-10.26 2.331-4.35 12.376-11.14 4.582-13.99-.863-4.59 11.024-8.75 4.111-10.25-.723-12.21 12.267-14.48 3.401-14.09-2.565z"
        fill="#0C4124" />
      <path
        d="M1384.29 511.313c5.29.364 10.71-10.867 12.76-16.527 12.16-26.384 29.08-4.936 29.08 1.537 0 5.178 8.37 12.724 12.55 15.85-3.14 6.541-5.85.232-6.81-3.741-.81 10.015-4.97 4.454-6.95.421-2.06 12.729-6.73 5.659-8.81.534-1.2 12.676-7.29 5.693-10.18.616-3.08 12.79-6.57 5.65-7.93.48-8.57 11.658-12.71 5.411-13.71.83z"
        fill="#24562B" />
      <path
        d="M1408.62 473.906c-3.31 11.528-13.73 18.652-18.91 20.797 1.47 6.063 10.37-.71 13.47-2.646.27 7.111 8.69 4.722 8.37-.508 5.39 6.152 6.91 4.099 9.3-.563 3.04 6.286 9.54 5.486 12.94 1.048-8.77.531-15.87-12.141-18.32-18.543-.9-4.665-3.54-11.114-6.85.415z"
        fill="#386A3F" />
    </g>
    <g opacity=".75">
      <path fill="#7C5939" d="M1362.07 533.95h11.013v9.361h-11.013z" />
      <path
        d="M1347.56 534.013c4.61-.554 8.62-9.867 10.05-14.454 6.2-15.081 19.3-4.264 20.04-1.213.59 2.441 6.72 12.894 9.72 17.815-2.14 6.734-4.61 2.228-5.59-.866-.22 7.169-4.91 4.081-7.22 1.641-3.07 8.717-7.85 3.227-9.85-.607-3.24 7.763-6.17 2.895-7.22-.509-8.6 8.639-10.2 2.395-9.93-1.807z"
        fill="#0C4124" />
      <path
        d="M1348.73 522.912c3.72.256 7.54-7.653 8.98-11.639 8.56-18.581 20.48-3.477 20.48 1.082 0 3.647 5.89 8.961 8.84 11.162-2.21 4.607-4.12.164-4.79-2.634-.57 7.053-3.5 3.137-4.9.297-1.45 8.964-4.74 3.985-6.21.375-.84 8.928-5.13 4.01-7.17.435-2.16 9.007-4.62 3.978-5.58.338-6.04 8.209-8.95 3.81-9.65.584z"
        fill="#24562B" />
      <path
        d="M1365.86 496.568c-2.33 8.118-9.67 13.136-13.32 14.646 1.04 4.27 7.31-.499 9.49-1.863.19 5.008 6.12 3.325 5.9-.358 3.79 4.333 4.86 2.887 6.55-.396 2.13 4.427 6.71 3.863 9.11.738-6.18.374-11.18-8.551-12.9-13.06-.64-3.285-2.5-7.826-4.83.293z"
        fill="#386A3F" />
    </g>
    <g opacity=".9">
      <path fill="#7C5939" d="M963.266 606.579l25.838.366-.558 28.463-25.838-.366z" />
      <path
        d="M929.225 606.239c10.843-1.09 20.655-21.846 24.206-32.088 15.225-33.623 45.476-8.924 47.069-2.055 1.28 5.495 15.21 29.146 22.02 40.284-5.31 15.034-10.92 4.846-13.06-2.128-.85 16.073-11.705 8.992-17.026 3.442-7.578 19.451-18.55 6.978-23.088-1.69-7.931 17.307-14.582 6.29-16.916-1.381-20.559 19.092-24.036 5.032-23.205-4.384z"
        fill="#0C4124" />
      <path
        d="M932.444 581.377c8.728.698 18.03-16.916 21.591-25.811 20.91-41.396 48.205-7.117 48.005 3.109-.16 8.18 13.44 20.297 20.25 25.333-5.39 10.259-9.67.229-11.13-6.068-1.65 15.802-8.36 6.919-11.502.502-3.798 20.06-11.3 8.782-14.577.636-2.372 19.998-12.217 8.823-16.844.736-5.481 20.134-11.03 8.771-13.119.573-14.522 18.215-21.167 8.249-22.674.99z"
        fill="#24562B" />
      <path
        d="M973.802 522.851c-5.828 18.134-23.263 29.146-31.882 32.412 2.237 9.613 17.158-.878 22.328-3.865.231 11.24 14.219 7.664 13.858-.605 8.705 9.844 11.285 6.636 15.385-.672 4.818 10.001 15.589 8.889 21.339 1.958-14.51.634-25.839-19.551-29.691-29.723-1.35-7.391-5.508-17.64-11.337.495z"
        fill="#386A3F" />
    </g>
    <ellipse cx="1168.72" cy="576.903" rx="7.46" ry="2.903" fill="#E56138" />
    <path
      d="M1161.91 581.799v-2.614c5.58 1.896 11.34.967 13.52.266v2.104c.22.68-.82 2.074-6.75 2.216-5.93.142-6.98-1.255-6.77-1.972z"
      fill="#E56138" />
    <path
      d="M1179.29 589.309c-6.53 2.71-16.94 1.55-21.04-.863-2.45 4.321-1.02 16.121 0 21.049 9.73 4.976 17.72 2.073 20.49 0 2.26-9.937 1.54-15.825.55-20.186z"
      fill="#B4EBEB" />
    <path d="M1165.96 605.706c-1.69-3.117.99-7.161 2.54-8.793v10.104l-2.54-1.311z" fill="#FFED8E" fill-opacity=".7" />
    <path
      d="M1168.94 607.197l-.98-.517c.15-2.963.43-8.379.47-9.413v-.138c.01-.078.01-.024 0 .138.05.703.71 2.441 1.05 3.275 2.04 2.317 1.87 4.529 1.53 5.345l-2.07 1.31z"
      fill="#F5D340" fill-opacity=".7" />
    <path
      d="M1166.59 609.585c-3.43.018-5.42.79-5.99 1.174.42.288 2.32.966 6.56 1.374 4.24.408 7.53-.746 8.64-1.374-.69-.93-4.94-1.196-9.21-1.174z"
      fill="#953621" stroke="#953621" />
    <path
      d="M1164.34 610.574c-2.79.337-1.52.761-.53.931 1.9.744 6.29.472 8.24.243 1.07-.081 2.91-.376 1.62-.908-1.6-.665-5.83-.687-9.33-.266z"
      fill="#681D0C" stroke="#5B1D10" />
    <path d="M1166.52 610.625l.47-2.925c1.31-.762 2.47-.317 2.89 0v2.925c-1.14.922-2.72.384-3.36 0z" fill="#C4C4C4" />
    <path
      d="M1159.24 610.869c-.8-.302-.95-1.248-1.01-1.684 9.04 5.14 17.38 2.142 20.51 0-.03.851-.77 1.477-1.13 1.684v1.506c2.26 1.206 2.94 4.402 3 5.85-8.01 4.839-19.62 2.267-24.42.376-.4-3.651 1.87-6.13 3.05-6.912v-.82z"
      fill="#E56138" />
    <path d="M1159.3 611.002c3.47 1.728 11.99 4.147 18.35 0" stroke="#953621" stroke-width="4" />
    <path
      d="M1179.32 588.914c-9.1 3.988-18.06 1.662-21.4 0 .7-.461 2.81-6.226 4.35-6.226 7.43 2.238 12.13-.089 12.89 0 .75.089 2.18 2.171 2.47 3.412.23.993 1.22 2.29 1.69 2.814zM1161.26 578.808v-1.883c1.36 1.307 1.76 1.573 7.37 1.883 4.49.248 6.82-.798 7.42-1.352v1.618c-.51.495-2.71 1.227-7.42 1.263-4.7.035-6.87-1.005-7.37-1.529z"
      fill="#953621" />
    <path
      d="M1154.13 588.18c.87-2.676 4.62-3.7 6.47-3.877l-1.52 2.276c-.75.022-1.66.817-2.93 2.377-1.58 1.95-1.4 12.828-1.4 17.57 0 3.793 2.41 6.721 3.61 7.711l-1.34 2.016c-1.87-1.485-2.89-2.792-4.45-7.467s.47-17.26 1.56-20.606zM1182.87 588.247c-.88-2.677-4.28-3.7-6.14-3.878l1.16 2.276c.76.022 1.67.457 2.94 2.016 1.59 1.95 1.41 13.19 1.41 17.931 0 3.794-2.41 6.721-3.62 7.711l1.34 2.016c1.88-1.484 2.91-2.791 4.47-7.467 1.56-4.675-.47-17.26-1.56-20.605z"
      fill="#E56138" />
    <path
      d="M1158.15 588.579c-.51 3.752 2.75 12.922 20.53 20.296-1.5 2.341-11.38 5.849-20.09.421 7.36-3.257 21.33-11.535 20.78-20.097-4.64 1.323-15.24 3.191-21.22-.62z"
      stroke="#E56138" stroke-width="5" />
    <path
      d="M670.888 621.832c27.346-1.437 54.257-48.808 64.294-72.313 41.913-76.805 115.159-16.169 118.414 0 2.604 12.935 34.995 69.468 50.865 96.118-14.975 34.495-27.942 10.181-32.554-6.288-3.906 37.369-30.383 19.763-43.133 6.288-21.16 44.556-47.339 14.373-57.783-6.288-21.811 39.525-37.301 13.175-42.32-4.941-53.713 42.4-60.902 9.283-57.783-12.576z"
      fill="#0C4124" />
    <path
      d="M681.696 564.221c21.838 2.516 47.125-37.586 57.039-57.951 57.039-94.339 121.819-11.68 120.189 12.129-1.304 19.048 31.507 48.667 48.076 61.096-14.668 23.36-24.31-.449-27.298-15.274-5.866 36.658-21.729 15.274-28.926 0-11.734 46.361-29.335 19.317-36.668 0-8.149 46.361-31.643 19.317-42.372 0-15.971 46.361-28.655 19.317-33.001 0-38.461 40.97-54.051 17.071-57.039 0z"
      fill="#24562B" />
    <path
      d="M791.955 432.043c-16.622 41.667-61.604 65.553-83.603 72.288 4.563 22.629 43.176-.299 56.484-6.735-.651 26.217 34.859 19.307 34.859 0 20.778 23.827 27.609 16.613 38.703 0 11 23.797 38.16 22.3 53.37 6.735-36.503 0-62.74-48.192-71.295-72.288-2.58-17.361-11.896-41.667-28.518 0z"
      fill="#386A3F" />
    <path d="M1272.93 489.201s-60 92.953-79.93 91.352" stroke="#B21C14" stroke-width="6" stroke-miterlimit="10" />
    <path d="M1381.78 601.476l-72.87 9.033-.8-44.019 73.1 3.202.57 31.784z" fill="#953621" />
    <path
      d="M1388.27 564.89s4.78 7.088-6.49 7.317c-11.27.229-61.26 3.887-61.26 3.887s-7.74-4.573-1.59-5.145c6.03-.686 69.34-6.059 69.34-6.059z"
      fill="#C45233" />
    <path
      d="M1308.11 566.948l.8 42.074-86.53-9.032-.69-36.93 40.31-55.222 46.11 59.11zM1334.07 486l54.2 78.89-69.34 5.945-55.22-81.405 70.36-3.43z"
      fill="#E56138" />
    <path
      d="M1215.66 567.748l48.05-78.318 56.24 83.006s3.3 9.718-6.03 4.23c-9.34-5.488-43.27-43.789-47.03-54.994-3.75-11.205-7.17-.915-9.67 2.744-2.51 3.659-21.3 33.728-35.41 42.189 0 0-8.54 8.918-6.15 1.143z"
      fill="#F5D340" />
    <path d="M1264.5 532.991s4.67 41.617 42.93 75.917l-82.21-8.689c.12 0 36.66-44.133 39.28-67.228z" fill="#953621" />
    <path d="M1272.93 489.201S1350.01 618.969 1383.26 624M1326.67 486s77.08 129.768 110.33 134.799" stroke="#B21C14"
      stroke-width="6" stroke-miterlimit="10" />
    <path
      d="M1206.34 620.643c-4.23-2.363-8.07-1.989-9.46-1.507l2.51 20.946c3.92-.533 7.41-3.652 8.66-5.145l17.76-3.993c2.68-.604 3.92-2.82 4.2-3.852.11-.896.07-3.636-.89-7.43-.96-3.795-3.75-3.589-5.03-3.012l-17.75 3.993z"
      fill="#B4EBEB" />
    <path
      d="M1209.27 619.963l-2.51.564c.43 1.812 2.45 3.328 3.41 3.859 3.49-.591 10.92-1.993 12.75-2.867 1.83-.874 2.48-3.834 2.58-5.205l-2.4.541c-.79-.632-1.46-.01-1.69.38l-1.39.31c-.79-.631-1.42-.017-1.63.369l-1.34.299c-.91-.604-1.5.002-1.68.38l-1.39.311c-.75-.641-1.4-.021-1.64.368l-1.38.311c-.79-.631-1.45-.01-1.69.38zM1211.16 634.507l-2.49.558c-.01-1.903 1.53-4.221 2.3-5.142 3.41-.958 10.7-2.868 12.62-2.837 1.92.031 3.22 2.551 3.63 3.808l-2.38.536c-.59.943-1.36.643-1.67.376l-1.37.307c-.59.943-1.33.636-1.62.365l-1.32.296c-.72.971-1.41.655-1.67.376l-1.37.308c-.55.934-1.31.632-1.62.365l-1.37.308c-.59.943-1.36.643-1.67.376zM1200.61 641.974l-4.12.925c-.89-.843-.55-1.857-.27-2.259l-.42-3.531c-.94-.834-.57-1.853-.27-2.259l-.43-3.601c-.94-.834-.57-1.853-.27-2.259l-.41-3.389c-.9-.9-.57-2.022-.29-2.471l-.41-3.389c-.8-.749-.51-1.818-.27-2.26l4.11-.925c.69.485.47 1.708.27 2.26l.41 3.389c.7.597.49 1.896.29 2.471l.41 3.389c.75.644.51 1.916.3 2.471l.4 3.389c.71.654.48 1.779.27 2.259l.43 3.531c.74.644.49 1.775.27 2.259z"
      fill="#52C2C2" />
    <path
      d="M1202.86 629.799c-.82-1.017-.69-2.176-.34-3.017.23-.537.75-.856 1.3-.978 1.19-.268 2.28.499 2.42 1.713l.01.069c.14 1.183-.69 2.353-1.85 2.614-.58.13-1.18.037-1.54-.401z"
      fill="#1B365D" />
    <path d="M1204.78 626.653l-1.14.256.34 2.853 1.14-.256-.34-2.853z" fill="#102037" />
    <path
      d="M-172 903.867c0-69.292 58.461-124.217 127.618-119.9L725 832l760.35-47.95c69.16-4.361 127.65 50.557 127.65 119.856 0 66.326-53.77 120.094-120.09 120.094H-51.867C-118.215 1024-172 970.215-172 903.867z"
      fill="url(#paint3_linear)" />
    <g filter="url(#filter0_d)">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M-14.995 693H1466.71v42.927H1674v83.889c-126.44-93.476-299.08-93.476-425.53 0v-79.629h-51.39v79.629c-126.44-93.476-299.082-93.476-425.523 0v-79.629h-51.392v79.629c-126.442-93.476-299.084-93.476-425.526 0v-79.629h-41.113v79.629c-126.442-93.476-299.084-93.476-425.526 0v-83.889h157.005V693zm330.855 28.081h16.338v57.973H315.86v-57.973zm44.931 0h-16.339v45.291h16.339v-45.291zm12.254 0h16.338v36.233h-16.338v-36.233zm44.93 0h-16.338v25.363h16.338v-25.363zm12.254 0h16.339v20.834h-16.339v-20.834zm381.914 6.34h-16.339v57.973h16.339v-57.973zm12.253 0h16.339v45.292h-16.339v-45.292zm44.931 0h-16.338v36.234h16.338v-36.234zm12.254 0h16.339v25.364h-16.339v-25.364zm44.931 0h-16.338v20.835h16.338v-20.835zm348.218 0h16.34v57.973h-16.34v-57.973zm44.93 0h-16.34v45.292h16.34v-45.292zm12.25 0h16.34v36.234h-16.34v-36.234zm44.93 0h-16.34v25.364h16.34v-25.364zm12.26 0h16.34v20.835h-16.34v-20.835zm-702.56-6.34h16.339v57.973H686.54v-57.973zm-12.254 0h-16.338v45.291h16.338v-45.291zm-44.931 0h16.339v36.233h-16.339v-36.233zm-12.254 0h-16.338v25.363h16.338v-25.363zm-44.931 0h16.339v20.834H572.17v-20.834zm610.65 6.34h-16.34v57.973h16.34v-57.973zm-44.93 0h16.34v45.292h-16.34v-45.292zm-12.25 0h-16.34v36.234h16.34v-36.234zm-44.93 0h16.34v25.364h-16.34v-25.364zm-12.26 0h-16.34v20.835h16.34v-20.835zm-856.748-6.34h16.338v57.973h-16.338v-57.973zm-12.254 0h-16.339v45.291h16.339v-45.291zm-44.931 0h16.338v36.233h-16.338v-36.233zm-12.254 0h-16.339v25.363h16.339v-25.363zm-44.931 0h16.338v20.834H97.332v-20.834z"
        fill="#7C5939" />
    </g>
    <path fill="#392512" d="M-20.412 679h1467.2v32h-1467.2z" />
    <rect x="1253.2" y="689" width="206" height="61.133" rx="10" transform="rotate(90 1253.2 689)" fill="#708097" />
    <rect x="777.378" y="689" width="206" height="60.114" rx="10" transform="rotate(90 777.378 689)" fill="#708097" />
    <rect x="302.576" y="689" width="206" height="61.133" rx="10" transform="rotate(90 302.576 689)" fill="#708097" />
    <defs>
      <linearGradient id="paint0_linear" x1="706.138" y1="0" x2="706.138" y2="960" gradientUnits="userSpaceOnUse">
        <stop offset=".266" stop-color="#CDFFFF" />
        <stop offset="1" stop-color="#52C2C2" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="706" y1="490.492" x2="706" y2="952" gradientUnits="userSpaceOnUse">
        <stop offset=".385" stop-color="#386A3F" />
        <stop offset=".927" stop-color="#1D8A4F" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="720.5" y1="776" x2="720.5" y2="1024" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B4EBEB" />
        <stop offset=".75" stop-color="#6499C6" />
      </linearGradient>
      <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-.03218 103 -103.038 -.0322 78.032 51)">
        <stop stop-color="#F2994A" />
        <stop offset=".521" stop-color="#F5D340" stop-opacity=".75" />
        <stop offset="1" stop-color="#F5D340" stop-opacity="0" />
      </radialGradient>
      <filter id="filter0_d" x="-176" y="693" width="1854" height="134.816" filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default DaytimeScene;