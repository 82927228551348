import React from 'react';

const RecruitmentIcon = () => (
    <svg width="284" height="284" viewBox="0 0 284 284" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M142 284C220.424 284 284 220.424 284 142C284 63.5756 220.424 0 142 0C63.5756 0 0 63.5756 0 142C0 220.424 63.5756 284 142 284Z" fill="#AAB4FE" />
        <path d="M79.307 214.114L71.8735 221.562L57.7209 208.35L157.348 110.137L173.304 106.24L170.192 122.607L79.307 214.114Z" fill="#E5E7FB" />
        <path d="M88.834 199.325L106.502 216.992L93.6364 229.858L75.3887 211.617L88.834 199.325Z" fill="#E5E7FB" />
        <path d="M109.189 178.587L126.857 196.255L113.992 209.121L95.7441 190.873L109.189 178.587Z" fill="#E5E7FB" />
        <path d="M189.161 128.378C209.661 128.378 226.279 111.76 226.279 91.26C226.279 70.7603 209.661 54.1421 189.161 54.1421C168.662 54.1421 152.043 70.7603 152.043 91.26C152.043 111.76 168.662 128.378 189.161 128.378Z" fill="#E5E7FB" />
        <path d="M189.161 113.086C201.216 113.086 210.988 103.314 210.988 91.2599C210.988 79.2054 201.216 69.4333 189.161 69.4333C177.107 69.4333 167.335 79.2054 167.335 91.2599C167.335 103.314 177.107 113.086 189.161 113.086Z" fill="#AAB4FE" />
    </svg>
)

export default RecruitmentIcon;