import React from 'react';

const SponsorshipVines = () => (
  <svg
    width="555"
    height="1001"
    viewBox="0 0 555 1001"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M228.5 15C293.833 53.6667 417.1 171.8 387.5 335C350.5 539 295.46 541 371 646C425.176 721.304 489.5 831.5 463 988.5M402.5 4C403.833 114.333 379.1 335 269.5 335C132.5 335 148 439.5 178.5 468.5C201.863 490.714 250.5 493.5 254.5 468.5C258.5 443.5 208 401.5 130.5 435C68.5 461.8 67.5001 564.5 87 609.5"
      stroke="#70E0A3"
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="380.5"
      cy="365.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="389.5"
      cy="674.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="468.5"
      cy="918.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="318.5"
      cy="93.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="298.5"
      cy="327.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="245.5"
      cy="478.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="75.5"
      cy="552.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <circle
      cx="403.5"
      cy="37.5"
      r="7"
      fill="#439A6B"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M352.826 760.265C392.257 751.052 395.854 723.518 392.213 708.261C391.806 706.56 390.175 705.463 388.418 705.563C341.551 708.237 341.688 739.829 348.506 758.034C349.153 759.763 351.012 760.689 352.826 760.265Z"
      fill="#E0F36D"
    />
    <path
      d="M357.248 729.905C357.247 729.888 357.248 729.85 357.265 729.787L357.265 729.787C358.636 724.756 361.199 719.758 364.238 714.75C366.071 711.729 368.043 708.757 370.018 705.78C371.336 703.794 372.655 701.806 373.935 699.801L373.087 699.259L373.942 699.789C374.011 699.677 374.13 699.544 374.313 699.409C374.494 699.276 374.722 699.154 374.986 699.058C375.25 698.963 375.535 698.899 375.822 698.872C376.11 698.845 376.385 698.856 376.629 698.899C376.875 698.942 377.071 699.015 377.214 699.096C377.357 699.176 377.429 699.255 377.463 699.306C377.493 699.352 377.5 699.387 377.499 699.42C377.499 699.459 377.486 699.533 377.421 699.64C376.166 701.605 374.822 703.616 373.464 705.648C371.587 708.458 369.682 711.31 367.946 714.137C364.926 719.053 362.289 724.088 360.904 729.19C360.84 729.425 360.629 729.692 360.175 729.92C359.727 730.145 359.143 730.275 358.576 730.28C357.989 730.286 357.561 730.159 357.353 730.021C357.307 729.991 357.281 729.966 357.267 729.95C357.254 729.935 357.251 729.928 357.251 729.927L357.251 729.927L357.251 729.927C357.251 729.926 357.248 729.921 357.248 729.905Z"
      fill="#70E0A3"
      stroke="#70E0A3"
      strokeWidth="2"
    />
    <path
      d="M378.52 699.177C378.529 699.181 378.535 699.185 378.54 699.188C381.374 705.835 383.011 715.476 379.737 725.122C376.506 734.642 368.3 744.73 350.374 752.075C350.293 752.108 350.22 752.105 350.166 752.088C350.141 752.08 350.125 752.07 350.117 752.063C350.11 752.058 350.103 752.051 350.094 752.036C345.42 743.995 342.066 733.001 344.829 723.14C347.49 713.646 356.193 703.999 378.307 699.158C378.394 699.139 378.472 699.154 378.52 699.177Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M264.953 162.949C302.767 165.38 313.61 141.771 314.588 127.018C314.704 125.273 313.466 123.746 311.755 123.333C269.023 113.009 260.402 141.277 261.479 159.556C261.588 161.4 263.093 162.829 264.953 162.949Z"
      fill="#E0F36D"
    />
    <path
      d="M277.163 136.781C277.166 136.768 277.175 136.74 277.205 136.693L277.205 136.693C279.819 132.538 283.501 128.738 287.617 125.057C290.101 122.836 292.695 120.698 295.294 118.557C297.029 117.127 298.766 115.696 300.473 114.237L299.82 113.471L300.484 114.228C300.571 114.151 300.708 114.067 300.904 113.998C301.099 113.929 301.332 113.883 301.592 113.869C301.851 113.856 302.121 113.876 302.383 113.93C302.646 113.983 302.886 114.067 303.089 114.171C303.294 114.275 303.445 114.391 303.547 114.497C303.649 114.604 303.687 114.687 303.7 114.732C303.712 114.771 303.708 114.792 303.702 114.811C303.695 114.835 303.669 114.89 303.587 114.963C301.918 116.389 300.154 117.833 298.37 119.293C295.903 121.313 293.398 123.364 291.055 125.437C286.979 129.043 283.211 132.864 280.555 137.093C280.444 137.27 280.197 137.447 279.735 137.526C279.278 137.605 278.725 137.562 278.219 137.413C277.693 137.259 277.354 137.031 277.215 136.862C277.18 136.819 277.167 136.792 277.163 136.781ZM277.161 136.775C277.161 136.775 277.161 136.776 277.161 136.777L277.161 136.775ZM277.162 136.785C277.162 136.788 277.162 136.79 277.161 136.79C277.161 136.79 277.161 136.788 277.162 136.785Z"
      fill="#70E0A3"
      stroke="#70E0A3"
      strokeWidth="2"
    />
    <path
      d="M304.567 115.076C304.571 115.08 304.574 115.083 304.577 115.086C305.27 121.827 304.069 130.864 298.511 138.567C293.028 146.166 282.962 152.943 264.965 154.66C264.882 154.668 264.817 154.645 264.775 154.616C264.755 154.602 264.745 154.59 264.739 154.582C264.735 154.576 264.731 154.569 264.727 154.553C262.761 146.043 262.805 135.33 267.964 127.306C272.919 119.599 283.296 113.342 304.381 115.001C304.466 115.008 304.532 115.043 304.567 115.076Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M12.367 611.269C47.6309 613.457 57.8558 591.5 58.8469 577.64C58.9717 575.895 57.7366 574.371 56.0245 573.961C16.1326 564.403 7.96243 590.706 8.90357 607.877C9.00464 609.721 10.5071 611.153 12.367 611.269Z"
      fill="#E0F36D"
    />
    <path
      d="M23.7274 586.645C23.7322 586.635 23.7402 586.619 23.7531 586.598L23.7531 586.598C26.2077 582.698 29.6651 579.128 33.5347 575.668C35.8703 573.58 38.3076 571.571 40.7504 569.558C42.3819 568.214 44.0158 566.867 45.6235 565.494L44.9704 564.728L45.6341 565.485C45.7093 565.418 45.8314 565.342 46.0099 565.28C46.1872 565.217 46.4013 565.174 46.6406 565.162C46.8793 565.149 47.128 565.168 47.3696 565.218C47.6114 565.267 47.8319 565.345 48.0183 565.439C48.2058 565.535 48.3422 565.639 48.4324 565.734C48.5224 565.828 48.5529 565.898 48.5621 565.93C48.5666 565.945 48.5669 565.954 48.5668 565.957C48.5668 565.96 48.5665 565.963 48.5645 565.969C48.561 565.98 48.5436 566.023 48.4734 566.086C46.906 567.424 45.2478 568.782 43.5707 570.155C41.2493 572.056 38.8916 573.986 36.6881 575.936C32.853 579.328 29.3045 582.926 26.8026 586.91C26.71 587.057 26.4964 587.218 26.0712 587.291C25.6517 587.363 25.141 587.324 24.6721 587.186C24.1845 587.042 23.8783 586.834 23.7577 586.687C23.7429 586.669 23.7334 586.655 23.7274 586.645ZM23.7183 586.627C23.7183 586.627 23.7186 586.628 23.7189 586.629C23.7184 586.628 23.7182 586.627 23.7183 586.627ZM23.7214 586.662C23.7211 586.664 23.7207 586.665 23.7206 586.665C23.7205 586.666 23.7206 586.664 23.7214 586.662Z"
      fill="#70E0A3"
      stroke="#70E0A3"
      strokeWidth="2"
    />
    <path
      d="M49.2512 566.43C49.2517 566.43 49.2522 566.431 49.2526 566.431C49.8658 572.733 48.7086 581.117 43.5328 588.258C38.4284 595.3 29.075 601.604 12.3488 603.238C12.2708 603.246 12.2116 603.224 12.1742 603.198C12.1563 603.185 12.147 603.174 12.1429 603.168C12.1399 603.164 12.1362 603.158 12.1328 603.143L8.71945 603.917L12.1328 603.143C10.3247 595.171 10.4163 585.23 15.2204 577.801C19.8241 570.683 29.4523 564.855 49.076 566.361C49.1609 566.368 49.2218 566.403 49.2512 566.43Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M216.462 294.595C232.917 325.755 256.923 323.061 269.244 316.707C270.799 315.905 271.456 314.057 270.915 312.382C258.33 273.453 231.69 280.152 217.553 289.872C216.032 290.919 215.592 292.947 216.462 294.595Z"
      fill="#E0F36D"
    />
    <path
      d="M243.334 291.473C243.346 291.472 243.363 291.47 243.386 291.471L243.386 291.471C247.984 291.535 252.82 292.625 257.777 294.123C260.77 295.027 263.746 296.059 266.73 297.094C268.723 297.785 270.719 298.477 272.724 299.133L273.037 298.177L272.737 299.137C272.832 299.167 272.96 299.231 273.106 299.35C273.252 299.469 273.399 299.629 273.534 299.826C273.668 300.023 273.781 300.244 273.865 300.475C273.948 300.707 273.997 300.935 274.013 301.142C274.029 301.351 274.011 301.522 273.977 301.647C273.944 301.773 273.9 301.835 273.878 301.859C273.868 301.87 273.861 301.875 273.858 301.876C273.856 301.878 273.853 301.879 273.847 301.88C273.837 301.883 273.791 301.891 273.702 301.864C271.748 301.224 269.73 300.517 267.689 299.802C264.864 298.812 261.994 297.807 259.189 296.943C254.307 295.439 249.399 294.287 244.705 294.225C244.533 294.223 244.286 294.125 244.003 293.801C243.724 293.481 243.492 293.026 243.366 292.556C243.235 292.066 243.253 291.698 243.315 291.519C243.323 291.498 243.329 291.483 243.334 291.473ZM243.346 291.455C243.346 291.455 243.345 291.456 243.344 291.457C243.345 291.456 243.346 291.455 243.346 291.455ZM243.317 291.476C243.315 291.477 243.313 291.478 243.313 291.478C243.313 291.478 243.314 291.477 243.317 291.476Z"
      fill="#70E0A3"
      stroke="#70E0A3"
      strokeWidth="2"
    />
    <path
      d="M273.802 302.705C273.802 302.705 273.802 302.706 273.802 302.706C268.754 306.5 261.015 309.868 252.248 309.169C243.602 308.48 233.378 303.791 223.299 290.401C223.252 290.339 223.24 290.277 223.243 290.232C223.244 290.21 223.249 290.196 223.252 290.19C223.254 290.185 223.257 290.179 223.268 290.168C229.118 284.488 237.63 279.405 246.448 279.638C254.897 279.862 264.859 285.031 273.769 302.52C273.808 302.596 273.81 302.666 273.802 302.705Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M444.146 419.026C411.256 429.793 396.109 411.535 391.713 398.619C391.15 396.963 391.959 395.178 393.513 394.35C428.979 375.447 443.258 398.332 446.639 414.871C447.008 416.681 445.917 418.446 444.146 419.026Z"
      fill="#E0F36D"
    />
    <path
      d="M427.282 398.429C427.275 398.421 427.266 398.411 427.253 398.4L427.253 398.399C423.956 395.303 419.787 392.769 415.253 390.44C412.516 389.034 409.703 387.73 406.883 386.422C405 385.549 403.113 384.674 401.244 383.767L401.683 382.861L401.232 383.761C401.146 383.718 401.014 383.676 400.83 383.661C400.648 383.646 400.436 383.658 400.207 383.705C399.979 383.752 399.748 383.831 399.532 383.937C399.316 384.044 399.127 384.171 398.975 384.306C398.822 384.442 398.719 384.573 398.658 384.684C398.597 384.794 398.587 384.865 398.586 384.894C398.585 384.908 398.587 384.914 398.587 384.915L398.587 384.915C398.588 384.916 398.588 384.918 398.59 384.922C398.594 384.928 398.619 384.963 398.699 385.004C400.519 385.886 402.431 386.766 404.365 387.655C407.043 388.886 409.762 390.137 412.339 391.443C416.825 393.717 421.09 396.257 424.457 399.425C424.576 399.537 424.813 399.637 425.233 399.601C425.646 399.565 426.119 399.403 426.529 399.157C426.955 398.901 427.191 398.629 427.267 398.464C427.274 398.45 427.278 398.438 427.282 398.429ZM427.288 398.404C427.288 398.404 427.288 398.404 427.288 398.406C427.288 398.404 427.288 398.404 427.288 398.404ZM427.295 398.447C427.296 398.449 427.297 398.45 427.297 398.45C427.297 398.45 427.297 398.449 427.295 398.447Z"
      fill="#70E0A3"
      stroke="#70E0A3"
      strokeWidth="2"
    />
    <path
      d="M398.254 385.474C398.175 385.501 398.127 385.549 398.106 385.58C399.093 391.706 402.27 399.351 408.935 404.828C415.508 410.23 425.921 413.89 442.164 411.319C442.24 411.307 442.29 411.272 442.319 411.238C442.332 411.222 442.338 411.21 442.34 411.203C442.342 411.199 442.344 411.192 442.343 411.177C442.077 403.166 439.522 393.784 433.138 387.946C427.025 382.356 416.474 379.21 398.254 385.474Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M472.742 90.004C441.709 99.7663 426.977 81.947 422.52 69.3346C421.924 67.6491 422.735 65.8153 424.316 64.982C457.684 47.394 471.69 69.6329 475.248 85.7517C475.657 87.6054 474.552 89.4346 472.742 90.004Z"
      fill="#E0F36D"
    />
    <path
      d="M431.307 54.9164L431.759 54.0248L431.294 54.91C431.216 54.8689 431.095 54.8283 430.926 54.8111C430.759 54.794 430.564 54.802 430.353 54.8429C430.142 54.8837 429.93 54.9544 429.732 55.0511C429.533 55.148 429.361 55.2647 429.223 55.3889C429.084 55.5138 428.993 55.6348 428.939 55.7359C428.885 55.8365 428.876 55.9025 428.876 55.931C428.876 55.9449 428.878 55.9523 428.878 55.9551C428.879 55.9577 428.88 55.9609 428.883 55.9657C428.889 55.9741 428.913 56.0073 428.986 56.0463C430.74 56.9347 432.583 57.8216 434.447 58.7189C437.03 59.9616 439.653 61.2243 442.14 62.5382C446.469 64.826 450.593 67.3704 453.866 70.5102C453.979 70.6183 454.195 70.7135 454.577 70.6867C454.958 70.66 455.395 70.5142 455.773 70.2871C456.166 70.0506 456.377 69.7994 456.442 69.6519C456.448 69.6384 456.452 69.6276 456.454 69.6194C456.448 69.611 456.437 69.5997 456.423 69.5855C453.218 66.5168 449.191 63.9822 444.817 61.6414C442.176 60.2281 439.465 58.913 436.746 57.5944C434.93 56.7134 433.111 55.8308 431.307 54.9164ZM456.459 69.5988C456.459 69.5988 456.459 69.5995 456.459 69.6008L456.459 69.5988ZM456.465 69.6348C456.466 69.6368 456.467 69.6378 456.467 69.6378L456.465 69.6348Z"
      fill="#70E0A3"
      stroke="#70E0A3"
      strokeWidth="2"
    />
    <path
      d="M428.688 56.5245C428.615 56.5489 428.571 56.5905 428.552 56.6177C429.62 62.5936 432.794 70.0513 439.241 75.4691C445.592 80.8073 455.513 84.498 470.796 82.3391C470.866 82.3292 470.91 82.2981 470.935 82.2684C470.947 82.2538 470.953 82.2422 470.955 82.236C470.956 82.2319 470.959 82.2244 470.958 82.2067C470.543 74.4237 467.929 65.2989 461.731 59.5372C455.805 54.0281 445.77 50.8524 428.688 56.5245Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M243.67 562.593C267.905 539.18 258.791 516.695 249.459 506.297C248.256 504.957 246.24 504.782 244.742 505.775C212.219 527.343 225.731 551.394 238.697 562.747C240.139 564.009 242.297 563.919 243.67 562.593Z"
      fill="#E0F36D"
    />
    <path
      d="M235.473 511.541L235.966 511.621C235.604 513.867 235.199 516.12 234.795 518.376C234.186 521.769 233.576 525.168 233.105 528.555C232.324 534.175 231.939 539.679 232.598 544.869C232.626 545.087 232.698 545.229 232.782 545.324C232.868 545.422 232.989 545.498 233.155 545.547C233.5 545.647 233.996 545.61 234.527 545.414C235.051 545.222 235.548 544.896 235.89 544.502C236.23 544.11 236.391 543.683 236.337 543.255C236.337 543.255 236.337 543.255 236.337 543.255L236.833 543.192L235.473 511.541ZM235.473 511.541L235.967 511.614C235.996 511.421 236.071 511.21 236.198 510.995C236.325 510.78 236.499 510.57 236.711 510.381C236.924 510.191 237.169 510.029 237.43 509.904C237.691 509.779 237.96 509.696 238.22 509.657C238.48 509.618 238.721 509.624 238.928 509.668C239.135 509.713 239.301 509.792 239.426 509.892C239.549 509.991 239.636 510.112 239.687 510.252C239.738 510.392 239.757 510.559 239.729 510.75L235.473 511.541Z"
      fill="#70E0A3"
      stroke="#70E0A3"
    />
    <path
      d="M239.805 510.2C239.805 510.2 239.806 510.2 239.807 510.2C244.06 514.946 248.219 522.416 248.592 531.261C248.96 539.976 245.677 550.524 234.167 561.643C234.114 561.694 234.06 561.711 234.018 561.712C233.998 561.713 233.984 561.71 233.977 561.707C233.971 561.705 233.963 561.702 233.948 561.689C227.808 556.314 221.913 548.224 221.095 539.349C220.312 530.849 224.109 520.546 239.635 510.249C239.704 510.204 239.769 510.196 239.805 510.2Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
    <path
      d="M533.72 984.691C493.46 982.094 483.852 951.958 483.998 934.194C484.013 932.303 485.471 930.729 487.31 930.482C533.277 924.29 540.237 959.599 537.715 981.32C537.479 983.347 535.701 984.818 533.72 984.691Z"
      fill="#E0F36D"
    />
    <path
      d="M500.266 928.383C510.629 937.733 522.367 946.699 529.481 957.995C531.125 960.606 525.649 962.818 524.005 960.207C517.169 949.335 505.554 940.6 495.579 931.607C495.336 931.381 495.156 931.117 495.05 930.827C494.943 930.538 494.912 930.23 494.957 929.922C495.003 929.613 495.125 929.308 495.317 929.027C495.508 928.745 495.765 928.491 496.072 928.279C496.38 928.068 496.733 927.902 497.11 927.793C497.487 927.684 497.882 927.632 498.271 927.642C498.661 927.652 499.037 927.722 499.379 927.849C499.722 927.976 500.023 928.158 500.266 928.383V928.383Z"
      fill="#70E0A3"
    />
    <path
      d="M494.814 929.827C494.746 938.128 496.973 949.308 503.848 958.651C510.633 967.873 522.168 975.616 541.385 976.855L541.077 980.388L541.385 976.855C541.471 976.861 541.535 976.833 541.578 976.797C541.6 976.779 541.612 976.761 541.62 976.748C541.626 976.737 541.633 976.721 541.636 976.692C542.846 966.277 541.711 952.989 535.177 943.176C528.861 933.69 517.007 926.728 495.03 929.688C494.943 929.7 494.878 929.741 494.84 929.783C494.823 929.802 494.817 929.815 494.816 929.819L494.815 929.819C494.815 929.82 494.814 929.821 494.814 929.827Z"
      stroke="#70E0A3"
      strokeWidth="7"
    />
  </svg>
);

export default SponsorshipVines;
