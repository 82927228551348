import React from 'react';

const LogoWhite = () => (
  <svg
    version="1.1"
    className="logo-small"
    id="Layer_1"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <defs>
      <style>
        {'.logo-navy-outline{fill:#1b365d;}.logo-fill{fill:#A5DCE8'}
      </style>
    </defs>
    <title>Vertical White</title>
    <path
      className="logo-navy-outline"
      d="M5.53,84.54V72.74a.43.43,0,0,1,.22-.38,1.11,1.11,0,0,1,1,0,.41.41,0,0,1,.22.38v5.05h4.76V72.74a.41.41,0,0,1,.22-.38.94.94,0,0,1,.51-.13,1,1,0,0,1,.52.13.41.41,0,0,1,.22.38v11.8a.42.42,0,0,1-.22.38.88.88,0,0,1-.52.14.87.87,0,0,1-.51-.14.42.42,0,0,1-.22-.38V79H7v5.52a.42.42,0,0,1-.22.38.9.9,0,0,1-.52.14.9.9,0,0,1-.52-.14A.44.44,0,0,1,5.53,84.54Z"
    />
    <path
      className="logo-navy-outline"
      d="M15,82.89A2,2,0,0,1,16,81a6.9,6.9,0,0,1,3.06-.54h.84v-.37a2.52,2.52,0,0,0-.48-1.54A1.68,1.68,0,0,0,18,77.86a3,3,0,0,0-1.3.27,5.19,5.19,0,0,1-.61.26.45.45,0,0,1-.4-.24.89.89,0,0,1-.15-.5c0-.27.27-.5.8-.71a4.79,4.79,0,0,1,1.7-.31,3.11,3.11,0,0,1,2.53,1A3.63,3.63,0,0,1,21.39,80v4.5a.45.45,0,0,1-.2.37.86.86,0,0,1-.52.15.77.77,0,0,1-.47-.14.45.45,0,0,1-.2-.37v-.68a3.5,3.5,0,0,1-2.65,1.33,2.44,2.44,0,0,1-1.71-.59A2.19,2.19,0,0,1,15,82.89Zm1.48-.16a1.28,1.28,0,0,0,.36,1,1.4,1.4,0,0,0,1,.34,2.21,2.21,0,0,0,1.43-.59,1.67,1.67,0,0,0,.69-1.22v-.93h-.61c-.34,0-.63,0-.88,0a5.16,5.16,0,0,0-.76.11,2,2,0,0,0-.65.23,1.18,1.18,0,0,0-.42.41A1.22,1.22,0,0,0,16.46,82.73Z"
    />
    <path
      className="logo-navy-outline"
      d="M23.09,81.8V80A3.47,3.47,0,0,1,24,77.58a3.41,3.41,0,0,1,2.61-1,3.93,3.93,0,0,1,1.83.39c.5.26.75.53.75.83a.75.75,0,0,1-.19.48.52.52,0,0,1-.43.24,3.11,3.11,0,0,1-.6-.3,2.55,2.55,0,0,0-1.26-.3,2.2,2.2,0,0,0-1.6.54,2.08,2.08,0,0,0-.54,1.54v1.74c0,1.39.72,2.08,2.16,2.08A2.5,2.5,0,0,0,28,83.56a3,3,0,0,1,.63-.31.58.58,0,0,1,.47.23.76.76,0,0,1,.18.48c0,.29-.26.57-.77.84a4.07,4.07,0,0,1-1.9.4,3.17,3.17,0,0,1-3.51-3.4Z"
    />
    <path
      className="logo-navy-outline"
      d="M30.73,84.54V72.23a.44.44,0,0,1,.2-.37.94.94,0,0,1,.52-.14,1,1,0,0,1,.53.14.43.43,0,0,1,.21.37v8.21l4-3.61a.55.55,0,0,1,.32-.13.77.77,0,0,1,.5.22.59.59,0,0,1,.23.47.45.45,0,0,1-.15.35L34.65,79.9l2.91,4.23a.51.51,0,0,1,.09.27.68.68,0,0,1-.27.5.81.81,0,0,1-.54.23.45.45,0,0,1-.4-.23l-2.8-4.09-1.45,1.3v2.43a.51.51,0,0,1-.22.36.85.85,0,0,1-.52.16.8.8,0,0,1-.51-.16A.44.44,0,0,1,30.73,84.54Z"
    />
    <path
      className="logo-navy-outline"
      d="M39.12,84.55V72.75a.44.44,0,0,1,.2-.38.77.77,0,0,1,.47-.14h3a4.54,4.54,0,0,1,2.61.65,2.63,2.63,0,0,1,.95,2.35A3.94,3.94,0,0,1,46,77.07a2.22,2.22,0,0,1-1.1,1,2.71,2.71,0,0,1,1.35,1,3.41,3.41,0,0,1,.46,1.94v.32a3.61,3.61,0,0,1-.95,2.77,3.54,3.54,0,0,1-2.53.88H39.79a.73.73,0,0,1-.48-.15A.44.44,0,0,1,39.12,84.55Zm1.48-7h2.19a2.22,2.22,0,0,0,1.6-.5,2.08,2.08,0,0,0,.51-1.56c0-1.25-.72-1.88-2.16-1.88H40.6Zm0,6.23H43c1.5,0,2.25-.8,2.25-2.41V81A2,2,0,0,0,43,78.72H40.6Z"
    />
    <path
      className="logo-navy-outline"
      d="M48.17,81.8V80a3.12,3.12,0,0,1,1-2.33A3.54,3.54,0,0,1,54,77.55a3,3,0,0,1,1,2.3,2.29,2.29,0,0,1-.12.82.82.82,0,0,1-.38.45,1.8,1.8,0,0,1-.47.17,4.68,4.68,0,0,1-.59,0H49.62v.53a1.88,1.88,0,0,0,.7,1.54,2.69,2.69,0,0,0,1.8.58,2.64,2.64,0,0,0,1-.17,5.56,5.56,0,0,0,.69-.34.85.85,0,0,1,.42-.17.52.52,0,0,1,.42.22.7.7,0,0,1,.18.45c0,.27-.27.54-.8.82a4.21,4.21,0,0,1-2,.42,4.15,4.15,0,0,1-2.81-.94A3.09,3.09,0,0,1,48.17,81.8Zm1.45-1.48h3.25a1.26,1.26,0,0,0,.62-.11.55.55,0,0,0,.15-.45,2,2,0,0,0-2-2,2,2,0,0,0-1.42.55,1.78,1.78,0,0,0-.6,1.36Z"
    />
    <path
      className="logo-navy-outline"
      d="M56.1,82.89a2,2,0,0,1,1-1.92,6.93,6.93,0,0,1,3.06-.54h.84v-.37a2.52,2.52,0,0,0-.48-1.54,1.68,1.68,0,0,0-1.45-.66,3,3,0,0,0-1.3.27,5.19,5.19,0,0,1-.61.26.45.45,0,0,1-.4-.24.89.89,0,0,1-.15-.5c0-.27.27-.5.8-.71a4.79,4.79,0,0,1,1.7-.31,3.11,3.11,0,0,1,2.53,1A3.63,3.63,0,0,1,62.51,80v4.5a.45.45,0,0,1-.2.37.86.86,0,0,1-.52.15.77.77,0,0,1-.47-.14.45.45,0,0,1-.2-.37v-.68a3.5,3.5,0,0,1-2.65,1.33,2.44,2.44,0,0,1-1.71-.59A2.19,2.19,0,0,1,56.1,82.89Zm1.48-.16a1.28,1.28,0,0,0,.36,1,1.4,1.4,0,0,0,1,.34,2.21,2.21,0,0,0,1.43-.59,1.67,1.67,0,0,0,.69-1.22v-.93h-.61c-.34,0-.63,0-.88,0a5.16,5.16,0,0,0-.76.11,2,2,0,0,0-.65.23,1.18,1.18,0,0,0-.42.41A1.22,1.22,0,0,0,57.58,82.73Z"
    />
    <path
      className="logo-navy-outline"
      d="M64.51,84.54V77.25a.44.44,0,0,1,.2-.37.94.94,0,0,1,.52-.14.78.78,0,0,1,.47.15.45.45,0,0,1,.2.37V78a2.42,2.42,0,0,1,.9-1,2.7,2.7,0,0,1,1.45-.4,2.88,2.88,0,0,1,2.17,1A3.38,3.38,0,0,1,71.34,80v4.53a.42.42,0,0,1-.22.38,1,1,0,0,1-1,0,.44.44,0,0,1-.22-.38V80a2,2,0,0,0-.59-1.42A1.89,1.89,0,0,0,67.88,78a1.85,1.85,0,0,0-1.33.57A2,2,0,0,0,66,80v4.52a.46.46,0,0,1-.23.36.84.84,0,0,1-.51.16.8.8,0,0,1-.51-.16A.44.44,0,0,1,64.51,84.54Z"
    />
    <path
      className="logo-navy-outline"
      d="M73.36,88.56V77.25a.44.44,0,0,1,.2-.37.89.89,0,0,1,.5-.14.81.81,0,0,1,.49.14.46.46,0,0,1,.2.38V78a2.66,2.66,0,0,1,.93-1,2.59,2.59,0,0,1,1.42-.4,3,3,0,0,1,2.24,1A3.38,3.38,0,0,1,80.26,80V81.8a3.44,3.44,0,0,1-.93,2.37,2.82,2.82,0,0,1-2.16,1,2.57,2.57,0,0,1-1.41-.4,2.45,2.45,0,0,1-.94-1v4.74a.51.51,0,0,1-.22.36.85.85,0,0,1-.52.16.85.85,0,0,1-.51-.15A.47.47,0,0,1,73.36,88.56Zm1.46-6.3a1.59,1.59,0,0,0,.6,1,2,2,0,0,0,1.38.59,1.87,1.87,0,0,0,1.4-.64,2,2,0,0,0,.6-1.44V80a2,2,0,0,0-.6-1.41A1.93,1.93,0,0,0,76.77,78a1.88,1.88,0,0,0-1.36.57A1.91,1.91,0,0,0,74.82,80Z"
    />
    <path
      className="logo-navy-outline"
      d="M81.7,81.8V80a3.21,3.21,0,0,1,1-2.37,3.35,3.35,0,0,1,2.43-1,3.39,3.39,0,0,1,2.44,1,3.17,3.17,0,0,1,1,2.37V81.8a3.24,3.24,0,0,1-1,2.37,3.38,3.38,0,0,1-4.85,0A3.27,3.27,0,0,1,81.7,81.8Zm1.46,0a2,2,0,0,0,.6,1.45,1.88,1.88,0,0,0,2.82,0,2,2,0,0,0,.6-1.44V80a2,2,0,0,0-.6-1.41A1.89,1.89,0,0,0,85.16,78a1.86,1.86,0,0,0-1.41.63A2,2,0,0,0,83.16,80Z"
    />
    <path
      className="logo-navy-outline"
      d="M90.36,82.41v-9a.42.42,0,0,1,.22-.36.91.91,0,0,1,.5-.15,1,1,0,0,1,.51.15.42.42,0,0,1,.22.36v3.37H94a.36.36,0,0,1,.31.18.68.68,0,0,1,.13.4.65.65,0,0,1-.13.39.35.35,0,0,1-.31.17H91.81v4.49a1.38,1.38,0,0,0,.33,1,1.61,1.61,0,0,0,1.13.31h.55a.56.56,0,0,1,.46.19.7.7,0,0,1,.17.47.67.67,0,0,1-.18.47.55.55,0,0,1-.45.2h-.55C91.33,85.06,90.36,84.18,90.36,82.41Z"
    />
    <path
      className="logo-navy-outline"
      d="M33.13,20.08a2.52,2.52,0,0,1-1.52-.5A2.56,2.56,0,1,1,35.19,19h0A2.57,2.57,0,0,1,33.13,20.08Zm0-3.52a1,1,0,0,0-.77.39,1,1,0,1,0,1.54,1.14,1,1,0,0,0-.2-1.34A1,1,0,0,0,33.13,16.56Z"
    />
    <path
      className="logo-navy-outline"
      d="M41.08,15.23a2.56,2.56,0,0,1-.37-5.09,2.53,2.53,0,0,1,1.9.48,2.58,2.58,0,0,1,.54,3.58h0a2.52,2.52,0,0,1-1.67,1A2.55,2.55,0,0,1,41.08,15.23Zm0-3.51H41a1,1,0,0,0-.43,1.71.91.91,0,0,0,.71.18,1,1,0,0,0,.63-.37h0a1,1,0,0,0-.2-1.34A.93.93,0,0,0,41.09,11.72Z"
    />
    <path
      className="logo-navy-outline"
      d="M33,58.49a2.54,2.54,0,1,1,2.06-1h0A2.58,2.58,0,0,1,33,58.49ZM33,55h-.14a.94.94,0,0,0-.63.37,1,1,0,0,0-.18.72,1,1,0,0,0,.38.62,1,1,0,0,0,1.34-.2.94.94,0,0,0-.2-1.33A.91.91,0,0,0,33,55Z"
    />
    <path
      className="logo-navy-outline"
      d="M46.92,28a2.56,2.56,0,1,1,1.95-.91A2.56,2.56,0,0,1,46.92,28Zm0-3.52h-.08a1,1,0,0,0-.65.34,1,1,0,1,0,1.46,1.23.94.94,0,0,0-.12-1.34A.93.93,0,0,0,46.92,24.5Z"
    />
    <path
      className="logo-navy-outline"
      d="M41.66,60.72l-2.34-.67,1.5-5.22a20.36,20.36,0,1,1,23-33.14c.24.2,6.85,5.87,6.36,13.73l-2.44-.15c.42-6.65-5.47-11.7-5.53-11.75A17.92,17.92,0,1,0,42.77,53.06a1.22,1.22,0,0,1,.68,1.45Z"
    />
    <path
      className="logo-fill"
      d="M64.29,25c3.16.45,7.95,4,7,12.65a25.55,25.55,0,0,1-9.78,16.8C59.23,55.93,54,58.07,50.7,55.64a8,8,0,0,1-3.27-7.2,10,10,0,0,1,4-7.15c4.78-3.75,4.47-6.48,4.48-7.59C55.91,31.44,57.43,24,64.29,25Z"
    />
    <path
      className="logo-navy-outline"
      d="M54.38,57.66a7,7,0,0,1-4.23-1.27,8.83,8.83,0,0,1-3.64-8,10.91,10.91,0,0,1,4.33-7.8C55,37.3,55,35,55,33.91v-.22c0-1.42.64-5.66,3.44-8.07a7.25,7.25,0,0,1,6-1.59h0c3.68.52,8.79,4.6,7.77,13.68A26.64,26.64,0,0,1,62,55.17,14.63,14.63,0,0,1,54.38,57.66Zm8.73-31.87A5.21,5.21,0,0,0,59.62,27c-2.26,1.95-2.79,5.56-2.79,6.68v.19c0,1.28,0,4.3-4.84,8.12a9.06,9.06,0,0,0-3.63,6.43v.13a7,7,0,0,0,2.9,6.31c2.74,2,7.42.3,9.72-1.26A24.66,24.66,0,0,0,70.35,37.5c.94-8.37-3.64-11.27-6.19-11.63h0A7.65,7.65,0,0,0,63.11,25.79Z"
    />
    <path
      className="logo-navy-outline"
      d="M25.25,44.26a1.2,1.2,0,0,1-.59-.15,1.19,1.19,0,0,1-.59-.76,26.91,26.91,0,0,1-.85-6.69,26.6,26.6,0,0,1,7.69-18.77l1.74,1.71a24.32,24.32,0,0,0-6.51,21.87L31,39.89l.76,2.32-6.13,2A1.19,1.19,0,0,1,25.25,44.26Z"
    />
    <path
      className="logo-navy-outline"
      d="M50,63.44A26.53,26.53,0,0,1,33.8,58L35.27,56A24.34,24.34,0,1,0,50,12.32a24.1,24.1,0,0,0-6.56.9l-.66-2.35A26.78,26.78,0,1,1,50,63.44Z"
    />
    <path
      className="logo-navy-outline"
      d="M34.82,48.3l-1.44-2,5.41-3.94A12.53,12.53,0,0,1,45.31,25.1l.91,2.27a10.08,10.08,0,0,0-4.8,14.72,1.22,1.22,0,0,1-.32,1.63Z"
    />
  </svg>
);

export default LogoWhite;
