import React from "react";

function AboutLeftFoot() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 361 186"
    >
      <path
        fill="#7C5939"
        d="M-10.037 19.015c-2.8 7.2 1.167 17.667 3.5 22 5.2-4.8 18.5-6.333 24.5-6.5-.5-6-2.7-18.7-7.5-21.5-6-3.5-17-3-20.5 6zM22.463 44.515c-5.2 8-18.167 11-24 11.5 3.05 25.6 16.185 35.604 26.757 38.547 5.561 1.548 11.79-.574 14.541-5.649 7.945-14.648-7.257-34.93-17.298-44.398z"
      />
      <path
        fill="#7C5939"
        d="M195.548 148.801c5.08 5.819 16.193 7.159 21.114 7.101-1.841-6.833 2.968-19.327 5.603-24.72-5.547-2.34-17.819-6.28-22.526-3.326-5.884 3.692-10.542 13.67-4.191 20.945zM233.212 131.833c4.676 8.317 1.321 21.196-.942 26.596 24.096 9.169 39.049 2.172 46.56-5.83 3.95-4.209 4.959-10.711 1.739-15.502-9.294-13.832-34.313-9.77-47.357-5.264z"
      />
      <path
        fill="#7C5939"
        d="M71.015 1.963c7.2-2.8 17.667 1.167 22 3.5-4.8 5.2-6.333 18.5-6.5 24.5-6-.5-18.7-2.7-21.5-7.5-3.5-6-3-17 6-20.5zM96.515 34.463c8-5.2 11-18.167 11.5-24 25.6 3.05 35.604 16.185 38.547 26.757 1.548 5.561-.574 11.79-5.649 14.541-14.648 7.945-34.931-7.257-44.398-17.298z"
      />
      <path
        fill="#7C5939"
        d="M284.387 63.02c7.22-2.748 17.658 1.294 21.975 3.658-4.837 5.166-6.466 18.454-6.675 24.453-5.997-.543-18.681-2.834-21.446-7.654-3.457-6.025-2.878-17.021 6.146-20.457zM309.654 95.702c8.037-5.142 11.13-18.087 11.672-23.917 25.577 3.234 35.486 16.44 38.353 27.033 1.509 5.572-.658 11.785-5.752 14.5-14.705 7.839-34.878-7.507-44.273-17.616z"
      />
    </svg>
  );
}

export default AboutLeftFoot;
